import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    height:100%;

    .case-box{
        width: 100%;
        height: 100%;
        background: #000;
        color: #fff;
    }
    .case-box .case-content{
        display: inline-block;
        margin-top: 85px;
        width: 100%;
        position: relative;
    }
    .case-box .case-map {
        width: 839px;
        height: 640px;
        position: relative;
        display: inline-block;
    }
    .case-map p{
        position: absolute;
        left: 0;
        top: 0;
        width: 498px;
        font-size: 16px;
        line-height: 22px;
    }
    .case-map img{
        width: 100%;
        height: 100%;
        background-size:100% 100%;
    }
    .jp-box{
        position: absolute;
        width: 270px;
        height: 696px;
        background: url("./img/jp-bg.png") no-repeat 0 0;
        right: 0;
        top: 0;
    }
    .jp-box .swiper-container{
        height: 230px;
        height: 664px;
        margin: 16px 20px;
    }
    .jp-box li{
        width: 230px;
        height: 160px;
        margin-bottom: 8px !important;
    }
    .jp-box li a{
        display: block;
        position: relative;
        
    }
    .jp-box li a img{
        width: 100%;
        height: 100%;
        background-size:100% 100%;
        border-radius: 8px;
    }
    .jp-box li a span{
        display: inline-block;
        width: 100%;
        height: 64px;
        padding: 7px 12px;
        position: absolute;
        left: 0;
        bottom: 0;
        background: #000;
        background: rgba(0,0,0,0.50);
        border-radius: 0 0 8px 8px;
        font-size: 14px;
        text-align: justify;
        line-height: 16px;
        color: #fff;

    }
    .jp-box li a span i{
        font-style: normal;
        width: 100%;
        height: 32px;
        display: block;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    @media screen and (max-height: 901px) {
        .case-content{
            margin-top:30px !important;
        }
        .case-box .case-map{
            width: 671px;
            height: 512px;
        }
        .case-map p{
            position: absolute;
            left: 0;
            top: 0;
            width: 498px;
            font-size: 14px;
            line-height: 22px;
        }
        .case-map img{
            width: 100%;
            height: 100%;
            background-size:100% 100%;
        }
        .jp-box{
            position: absolute;
            width: 216px;
            height: 556px;
            background: url("./img/jp-bg.png") no-repeat 0 0;
            background-size:100% 100%;
            right: 0;
            top: 0;
        }
        .jp-box .swiper-container{
            height: 184px;
            height: 534px;
            margin: 13px 16px;
        }
        .jp-box li{
            width: 184px;
            height: 128px;
            margin-bottom: 7px !important;
        }
        .jp-box li a{
            display: block;
            position: relative;
            
        }
        .jp-box li a img{
            width: 100%;
            height: 100%;
            background-size:100% 100%;
            border-radius: 8px;
        }
        .jp-box li a span{
            display: inline-block;
            width: 100%;
            height: 51px;
            padding: 6px 10px;
            position: absolute;
            left: 0;
            bottom: 0;
            background: #000;
            background: rgba(0,0,0,0.50);
            border-radius: 0 0 8px 8px;
            font-size: 12px;
            text-align: justify;
            line-height: 16px;
            color: #fff;
    
        }
        .jp-box li a span i{
            font-style: normal;
            width: 100%;
            height: 35px;
            display: block;
            word-break: break-all;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
    }
    @media screen and (min-height: 1280px) {
        .case-box>.h{
            display:flex;
            height:100%;
            align-items: center;
        }
        .case-box .case-content{
            margin-top:0;
        }
    }
`;

export default IndexStyleComponent;
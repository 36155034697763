import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    height:100%;

    .quick-box .h{
        position: relative;
        height:100%;
    }
    .quick-layout{
        width: 100%;
        height: 900px;
        position: relative;
    }
    .quick-box{
        width: 100%;
        height: 100%;
        background: rgb(237,241,245);
    }
    .jg-qk-bg{
        position: absolute;
        height: 100%;
        width:70%;
        left: 0;
        background-size:100% 100%;
    }

    .jg-quick-content{
        background: rgba(255,255,255,.7);
        display: inline-block;
        border-radius: 8px;
        width: 350px;
        height: 520px;
        position: relative;
        text-align: center;
        margin-top: 158px !important;
        margin-left: 850px;
    }
    .jg-quick-content img{
        position: absolute;
        width: 160px;
        height: 160px;
        background-size:100% 100%;
    }
    .jg-quick-content img:first-child{
        left: calc(50% - 80px);
        top: -120px;
    }
    .jg-quick-content img:last-child{
        width: 200px;
        height: 200px;
        left: calc(50% - 100px);
        bottom: -100px;
    }
    .jg-quick-content h2{
        font-weight: 700;
        text-align: center;
        margin-top: 50px !important;
        display: inline-block;
    }
    .jg-quick-content p{
        font-family: "MicrosoftYaHei";
        width: 300px;
        height: 171px;
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        text-align: justify;
        margin:20px 25px !important;
    }
    .jg-quick-content a{
        background: #1890FF;
        border-radius: 27px;
        font-size: 18px;
        letter-spacing: 0;
        text-align: center;
        width: 200px;
        height: 50px;
        margin-top: 20px;
        font-weight: 700;
        color: #fff !important;
        letter-spacing: 1px;
        line-height: 50px;
        display: inline-block;
    }
    .jg-quick-content a:hover{
        background: rgb(23,172,248);
        transition: .25s;
    }
    .jg-quick-content span{
        display: inline-block;
        width: 100%;
        font-size: 16px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        margin-top: 28px;
        font-weight: 700;
    }

    .jg-quick-swiper .swiper-container {
        position: absolute;
        width: 1000px;
        height: 180px;
        left: -260px;
        bottom:150px;
    }  

    @media screen and (max-width: 1441px) {
        .jg-quick-swiper .swiper-container {
            left: -100px;
        }
    }

    .jg-quick-swiper .swiper-container a{
        display: inline-block;
        padding: 20px;
        background: #fff;
        border-radius: 8px;
        position: relative;
        margin-right: 12px;
    }
    .jg-quick-swiper .swiper-container .oc-mask{
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        background: rgba(0,0,0,0.50);
        border-radius: 0 0 8px 8px;
    }
    .oc-mask h3,.oc-mask p{
        margin: 0 20px !important;
    }
    .oc-mask h3{
        width: calc(100% - 40px);
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 7px !important;
    }
    .oc-mask p{
        width: calc(100% - 40px);
        height:36px;
        font-size: 12px;
        color: #FFFFFF;
        margin-top: 10px !important;
        margin-bottom: 20px !important;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
    @media screen and (min-height: 1280px) {
        .quick-box .h{
            height:100%；
            display:flex;
            align-items: center;
        }
        .jg-quick-content{
            margin-top: 0 !important;
        }
    }
    @media screen and (max-height: 901px) {
        .jg-quick-swiper .swiper-container {
            left: -110px;
        }
        .jg-quick-content{
            background: rgba(255,255,255,.7);
            display: inline-block;
            border-radius: 8px;
            width: 280px;
            height: 416px;
            position: relative;
            text-align: center;
            margin-top: 126px !important;
            margin-left: 830px;
        }
        .jg-quick-content img{
            position: absolute;
            width: 128px;
            height: 128px;
            background-size:100% 100%;
        }
        .jg-quick-content img:first-child{
            left: calc(50% - 64px);
            top: -96px;
        }
        .jg-quick-content img:last-child{
            width: 160px;
            height: 160px;
            left: calc(50% - 80px);
            bottom: -80px;
        }
        .jg-quick-content h2{
            font-weight: 700;
            text-align: center;
            margin-top: 40px !important;
            display: inline-block;
        }
        .jg-quick-content p{
            font-family: "MicrosoftYaHei";
            width: 240px;
            height: 180px;
            font-size: 12px;
            color: #333333;
            letter-spacing: 0;
            text-align: justify;
            margin:12px 15px !important;
        }
        .jg-quick-content a{
            background: #1890FF;
            border-radius: 20px;
            font-size: 16px;
            letter-spacing: 0;
            text-align: center;
            width: 160px;
            height: 40px;
            margin-top: 12px;
            font-weight: 700;
            color: #fff !important;
            letter-spacing: 1px;
            line-height: 40px;
            display: inline-block;
        }
        .jg-quick-content a:hover{
            background: rgb(23,172,248);
            transition: .25s;
        }
        .jg-quick-content span{
            display: inline-block;
            width: 100%;
            font-size: 14px;
            color: #333333;
            letter-spacing: 0;
            text-align: center;
            margin-top: 20px;
            font-weight: 700;
        }
    
    }
`;
/*
@media screen and (min-width: 1441px) {
        .jg-qk-bg {
            width: 2.6rem;
            height: 2.12rem;
            right: 0;
            top: -.1rem;
        }
    }
*/
export default IndexStyleComponent;
import $ from 'jquery';

let contentArr=[$("<ul>\n" +
            "    <li>\n" +
            "        <img src=\"./img/smallprogram.png\" alt=\"smallprogram\" class=\"fll first\"/>\n" +
            "        <h2>注册便利</h2>\n" +
            "        <p>在“微信小程序”上填写手机号就能注册，通过会议形式集中注册上线，一次搞定</p>\n" +
            "    </li>\n" +
            "    <li>\n" +
            "        <img src=\"./img/wechat.png\" alt=\"wechat\" class=\"flr other-r\"/>\n" +
            "        <h2>使用便利</h2>\n" +
            "        <p>只要会用“微信”就可以使用，不需要单独购买电脑和学习打字，企业初始成本几乎为零</p>\n" +
            "    </li>\n" +
            "    <li>\n" +
            "        <img src=\"./img/photo.png\" alt=\"photo\" class=\"fll other-l\"/>\n" +
            "        <h2>录入便利</h2>\n" +
            "        <p>通过拍照上传、商品条码扫描、智能选择等功能，一个人，一部手机在厂里转一圈，就将原辅料采购、生产、检验到销售等生产记录及时、及地录入</p>\n" +
            "    </li>\n" +
            "    <li>\n" +
            "        <img src=\"./img/bbconvience.png\" alt=\"bbconvience\" class=\"flr other-r\"/>\n" +
            "        <h2>报备便利</h2>\n" +
            "        <p class=\"last-p\">生产报备、企业停产报告、主体责任自查报告等均可在线提交，真正做到“一次也不用跑”</p>\n" +
            "    </li>\n" +
            "</ul>"),
            $("<div class=\"text-block\">\n" +
            "    <div>\n" +
            "        <img src=\"./img/leftyinhao.png\" alt=\"leftyinhao\"/>\n" +
            "        <p>\n" +
            "        平台根据企业生产加工的特点和工艺要求，结合《食品安全法》和食品生产许可等法律法规的要求，为小企业创建了从企业资质管理、产品管理、采购管理、设备管理、供应商管理、生产管理、销售管理、从业人员管理、企业状态报备、主体责任自查、食品召回、食品安全事故应急直报等\n" +
            "        <span>12大管理模块</span>\n" +
            "        ，\n" +
            "        <span>涵盖企业全生产链</span>\n" +
            "        。简单易懂，节约管理成本，促进管理精细化、系统化、标准化。\n" +
            "        </p>\n" +
            "        <img src=\"./img/leftyinhao.png\" alt=\"leftyinhao\" class=\"r\"/>\n" +
            "    </div>\n" +
            "</div>"),
            $("<div class=\"text-block\">\n" +
            "<div>\n" +
            "   <img src=\"./img/leftyinhao.png\" alt=\"leftyinhao\"/>\n" +
            "   <p>\n" +
            "   数字化监管的核心是大数据的运用和数据安全。\n" +
            "   <span>企业方面</span>\n" +
            "   ，生产经营数据只要企业上传，就在云端保存二年，企业不可随意篡改。如果确实需要修改，需通过“\n" +
            "   <span>数据修改申请</span>\n" +
            "   ”通道提出申请，经辖区市场监管所同意后，才能在后台修改，企业管理刚性化进一步加强。\n" +
            "   <span>平台方面</span>\n" +
            "   ，所有企业上传信息在“阿里云”云端保存，并层层设置防火墙，由专人维护，定期备份数据，确保数据不丢失、不泄露、不被黑。\n" +
            "   </p>\n" +
            "   <img src=\"./img/leftyinhao.png\" alt=\"leftyinhao\" class=\"r\"/>\n" +
            "   </div>\n" +
            "</div>"),
            $("<div class=\"text-block\">\n" +
            "<div>\n" +
            "   <img src=\"./img/leftyinhao.png\" alt=\"leftyinhao\"/>\n" +
            "   <p>\n" +
            "       在“最多跑一次”改革的大背景下，牢固树立服务理念，在程序中增加了增值服务。例如“\n" +
            "       <span>e字典</span>\n" +
            "       ”，收集了企业常用的法律法规和技术规范，企业打开链接，就能在手机端即时查阅。这即方便了企业，解决了企业查询途径困难，也培养了企业自学的习惯。另外还设置了“\n" +
            "       <span>互动平台</span>\n" +
            "       ”、“\n" +
            "       <span>在线答疑</span>\n" +
            "       ”等渠道及时解答企业在生产管理中的疑难。在新版本中还设置了“\n" +
            "       <span>学习园地</span>\n" +
            "       ”和“\n" +
            "       <span>积分奖励</span>\n" +
            "       ”，积分累计到一定数额可以随时抵扣相关费用或委托检测费用等事宜。这些增值环节的设立，即强调企业主体责任落实，也让企业感受来之市场监管春风化雨般贴心地“服务”，增加企业对平台和市场监管的认同度、满意度。\n" +
            "   </p>\n" +
            "   <img src=\"./img/leftyinhao.png\" alt=\"leftyinhao\" class=\"r\"/>\n" +
            "   </div>\n" +
            "</div>"),
            $("<ul>\n" +
            "    <li>\n" +
            "        <img src=\"./img/suyuan.png\" alt=\"suyuan\" class=\"fll first\"/>\n" +
            "        <h2>逆向可溯源</h2>\n" +
            "        <p>在“微信小程序”上填写手机号就能注册，通过会议形式集中注册上线，一次搞定</p>\n" +
            "    </li>\n" +
            "    <li>\n" +
            "        <img src=\"./img/zuizong.png\" alt=\"zuizong\" class=\"flr other-r\"/>\n" +
            "        <h2>顺向可追踪</h2>\n" +
            "        <p>只要会用“微信”就可以使用，不需要单独购买电脑和学习打字，企业初始成本几乎为零</p>\n" +
            "    </li>\n" +
            "    <li>\n" +
            "        <img src=\"./img/umbrella.png\" alt=\"umbrella\" class=\"fll other-l\"/>\n" +
            "        <h2>风险可掌控</h2>\n" +
            "        <p>平台打破以往生产、销售、经营、餐饮等各环节各自为政的状况，整合为一，不谋一隅而谋全局，便于掌控全辖区食品安全状况。</p>\n" +
            "    </li>\n" +
            "    <li>\n" +
            "        <img src=\"./img/xiaolv.png\" alt=\"xiaolv\" class=\"flr other-r\"/>\n" +
            "        <h2>效率可提升</h2>\n" +
            "        <p class=\"last-p\">通过平台统计查询、双随机、预警等“滤镜”功能，对生产经营数据不正常、不合理、不规范的企业，针对性地开展线下实地核查，合理配置有限的监管资源，提高监管有效性。</p>\n" +
            "    </li>\n" +
            "</ul>")];

export default contentArr;
import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink,Link } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

class About extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'#666'
          }
        }
      }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="h fiexheight">
                        <div className="fiexh">
                            <div className="about-logo">
                                <Link to="/index">
                                    <img src="./img/about-logo.png" alt="about-logo" />
                                </Link>
                            </div>
                            <div className="content-box">
                                <div className="l-content">
                                    <img src="./img/briefintro.png" alt="briefintro" className="lr-title" />
                                    <p className="text">
                                        杭州南开承盛物联科技有限公司成立于2016年，专业从事软件研发、技术转让、技术咨询及相关服务的国家高新技术企业。主要为食品安全监管机构、食品生产企业、食品经营企业提供一站式信息化解决方案及相关服务，涉及食品安全相关信息化监管、溯源、农业物联网应用系统及相关大数据分析等业务。
                                        <br />
                                        <br />
                                        公司拥有一支信息化整体解决方案咨询、软件研发、系统运维和客户服务的专业团队，能一站式满足政府、企业的信息化建设和运维需要。成立至今，已先后完成了多个省级追溯平台的开发、建设工作，在国内建设完成了百余个区县追溯试点。
                                    </p>
                                </div>
                                <div className="r-content">
                                    <img src="./img/honour.png" alt="honour" className="lr-title" />
                                    <p className="p-first">通过ISO 9001:2015质量管理体系</p>
                                    <p>国家高新技术企业</p>
                                    <p>浙江省科技型中小企业</p>
                                    <p>杭州市高新技术企业</p>
                                    <p>杭州市科技型初创企业培育工程企业</p>
                                    <p>萧山区科技型中小微企业</p>
                                    <p>双软企业认证</p>
                                    <p>拥有50余项相关领域知识产权</p>
                                    <Link to="/honour">查看更多&gt;&gt;</Link>
                                </div>
                                <div className="about-img">
                                    <img src="./img/img_aboutus.png" alt="aboutus" />
                                </div>
                            </div>
                        </div>
                        <FooterCopy pStyle={this.state.style}></FooterCopy>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default About;
import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

class QuickGz extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'#666'
          }
        }
      }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                <div className="quickgz-layout">
                    <Head></Head>
                    <div className="h">
                        <div className="quickgz-content">
                            <div className="gz-left">
                                <i></i>
                                <h2 className="gz-title">公众端</h2>
                                <div className="qycode-box">
                                    <img src="./img/img_big.png" alt="img_big" />
                                    <p>通过企业门户展示牌，公众可查看企业的基本信息（简介、证照、从业人员）、荣誉资质、企业实力、云视频（阳光车间、阳光厨房），让公众实时监督、了解相关食品安全生产经营状况从而倒逼企业落实主体责任，并对相关产品/菜品以及企业进行评价，建立完善食品安全社会共享共治体系。</p>
                                </div>
                            </div>
                            <div className="gz-right">
                                <img src="./img/img_samll.png" alt="img_samll" />
                            </div>
                        </div>
                    </div>
                    <FooterCopy pStyle={this.state.style}></FooterCopy>
                </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default QuickGz;
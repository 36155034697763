import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';

import Swiper from 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';
import FooterCopy from '../../components/footer-copy';

import $ from 'jquery';

import globalData from '../../configs/configs';

class QuickQy extends Component{

    constructor(){
        super();
        this.state={
            style:{
                height:null,
                color:'#333'
            }
        }
    }
    
    componentDidMount(){
        let height=(parseInt($(".App").css("height"))-82)+"px";
        this.setState({
            style:{
                height
            }
        });
        new Swiper ('.swiper-container', {
            loop: true,  //循环
            speed:1800,
            autoplay : {
                delay:4000
            },
            pagination: {  //分页器
                el: '.swiper-pagination'
            }
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <img src="./img/img_qy.png" className="qy-qk-bg" />
                    <div className="quick-box">
                        <div className="h">
                            <div className="qy-quick-content">
                                <img src="./img/logo_qy.png" alt="logo_qy" />
                                <h2>企业端</h2>
                                <p>根据食品生产经营状态设置四大功能区块---食品生产、流通、餐饮/食堂、食品加工小作坊。根据相关法律法规要求，设定了企业资质、从业人员管理、合格供应商、原辅料/添加剂采购、生产过程控制、产品标签、出厂检验、人员晨检、消毒台账、留样台账、企业主体责任自查报告、问题食品召回、食品安全事故直报、云视频等模块，涵盖了食品生产经营全产业链，环环相扣，简单易懂。</p>
                                {/* <Button type="primary" shape="round" className="qy-login ant-btn">网页端登录</Button> */}
                                <a href={globalData.qyUrl} className="jg-login" target="_blank">网页端登录</a>
                                <span>扫码使用小程序</span>
                                <img src={globalData.qyMiniIcoPath} alt="ma_qy" />
                            </div>
                            <div className="qy-quick-swiper">
                                <div className="swiper-container">
                                    <div className="swiper-wrapper banner-box">
                                        <div className="swiper-slide">
                                            <a href="javascript:;" title="对企业所生产的产品进行记录，包括原辅料、添加剂的使用记录、关键控制点的记录。">
                                                <img src="./img/scgcgl.png" alt="scgcgl"/>
                                                <div className="oc-mask">
                                                    <h3>生产过程管理</h3>
                                                    <p>对企业所生产的产品进行记录，包括原辅料、添加剂的使用记录、关键控制点的记录。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="经营企业-食品销售类型企业特有功能，根据商品保质期设置临保预警时间、预警。">
                                                <img src="./img/lbyj.png" alt="scgcgl"/>
                                                <div className="oc-mask">
                                                    <h3>临保预警</h3>
                                                    <p>经营企业-食品销售类型企业特有功能，根据商品保质期设置临保预警时间、预警。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="经营企业-餐饮类型企业特有功能，每天登记人员的在岗晨检情况。">
                                                <img src="./img/lbyj.png" alt="scgcgl"/>
                                                <div className="oc-mask">
                                                    <h3>人员晨检</h3>
                                                    <p>经营企业-餐饮类型企业特有功能，每天登记人员的在岗晨检情况。</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div class="swiper-slide">
                                            <a href="javascript:;" title="对企业所生产的产品进行记录，包括原辅料、添加剂的使用记录、关键控制点的记录。">
                                                <img src="./img/scgcgl.png" alt="scgcgl"/>
                                                <div className="oc-mask">
                                                    <h3>生产过程管理</h3>
                                                    <p>对企业所生产的产品进行记录，包括原辅料、添加剂的使用记录、关键控制点的记录。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="经营企业-食品销售类型企业特有功能，根据商品保质期设置临保预警时间、预警。">
                                                <img src="./img/lbyj.png" alt="scgcgl"/>
                                                <div className="oc-mask">
                                                    <h3>临保预警</h3>
                                                    <p>经营企业-食品销售类型企业特有功能，根据商品保质期设置临保预警时间、预警。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="经营企业-餐饮类型企业特有功能，每天登记人员的在岗晨检情况。">
                                                <img src="./img/lbyj.png" alt="scgcgl"/>
                                                <div className="oc-mask">
                                                    <h3>人员晨检</h3>
                                                    <p>经营企业-餐饮类型企业特有功能，每天登记人员的在岗晨检情况。</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterCopy pStyle={this.state.style}></FooterCopy>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default QuickQy;
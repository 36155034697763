import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    height: 100%;

    .small-box{
        width: 100%;
        height: 100%;
        background: url("./img/bg_mini.png") no-repeat center center;
        background-size:100% 100%;
    }
    .small-box .h{
        position: relative;
    }
    .phone-box{
        position: absolute;
        left: 43px;
        top: 108px;
    }
    .phone-box img{
        width: 492px;
        height: 600px;
    }
    .small-content{
        position: absolute;
        top: 74px;
        left: 620px;
    }
    .small-content .small-title{
        font-size: 96px;
        color: #FFFFFF;
        letter-spacing: 14px;
        width: 440px;
    }
    .small-tips{
        margin-top: 8px;
    }
    .small-tips i{
        display:inline-block;
        width: 16px;
        height: 16px;
        background: url("./img/icon_feature_mini.png") no-repeat 0 0;
    }
    .small-tips span{
        font-size: 16px;
        color: #FFFFFF;
        letter-spacing: 2px;
        display: inline-block;
        margin-left: 4px;
        margin-right: 15px;
    }
    .small-code{
        margin-top: 50px !important;
    }
    .small-code div{
        width: 190px;
        display: inline-block;
    }
    .small-code div span{
        display: inline-block;
        width: 100%;
        font-size: 17px;
        color: #FFFFFF;
        letter-spacing: 2.8px;
        text-align: center;
        margin-top: 20px;
    }
    .small-code img{
        width: 190px;
        height: 190px;
    }
    .small-code div:first-child{
        margin-right: 40px !important;
    }
    @media screen and (max-height: 901px) {
        .phone-box{
            margin-top:-80px;
        }
        .small-content{
            margin-top:-70px;
        }
    }
    @media screen and (min-height: 1280px) {
        .small-box{
            display:flex;
            display:flex;
            align-items: center;
        }
        .small-box>.h{
            height:625px;
        }
        .phone-box{
            margin-top:0;
        }
        .small-content{
            margin-top:0;
        }
    }
`;

export default IndexStyleComponent;
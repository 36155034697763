import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import {Button} from 'antd';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

import $ from 'jquery';

import globalData from '../../configs/configs';

class Index extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'rgba(255,255,255,0.87)'
          },
          isHome:true,
          isIE:true,
          videoStyle:{
            left:'277px'
          }
        }
      }

    componentDidMount(){

        function getExplore() {
            let userAgent = navigator.userAgent;
            if (userAgent.indexOf("Opera") > -1 || userAgent.indexOf("OPR") > -1) {
                return 'Opera';
            } else if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1) {
                return 'IE';
            } else if (userAgent.indexOf("Edge") > -1) {
                return 'Edge';
            } else if (userAgent.indexOf("Firefox") > -1) {
                return 'Firefox';
            } else if (userAgent.indexOf("Safari") > -1 && userAgent.indexOf("Chrome") == -1) {
                return 'Safari';
            } else if (userAgent.indexOf("Chrome") > -1 && userAgent.indexOf("Safari") > -1) {
                return 'Chrome';
            } else if (!!window.ActiveXObject || "ActiveXObject" in window) {
                return 'IE>=11';
            } else {
                return 'Unkonwn';
            }
        }

        let isIE = getExplore().indexOf('IE') !== -1;

        this.setState({
            isIE,
            videoStyle:isIE ? {
                left:'277px'
            } : {
                left: '367px'
            }
        });


        $(".l-login").on("click",function(){
            window.open(globalData.jgUrl);
        });
        $(".r-login").on("click",function(){
            window.open(globalData.qyUrl);
        });
        
    }

    render(){
        const from=window._mode.from || 'hz';
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head isHome={this.state.isHome}></Head>
                    <div className="h">
                        <div className="home-content">
                            <div className="left-box">
                                <h2>
                                    <img className="title-bg" src="./img/title-bg.png" />
                                </h2>
                                <p>“{globalData.mainTitle}” 是由杭州南开承盛物联科技有限公司开发的数字化监管平台。平台把生产（含小作坊）、流通（校云食安）、餐饮等各种业态纳为一体，以追溯为核心功能，以落实企业主体责任为落脚点，形成全链式食品安全数字化监管，创新监管手段，提升监管时效性。</p>
                                <Button type="primary" shape="round" className="l-login">监管端网页登录</Button>
                                <Button type="primary" shape="round" className="r-login">企业端网页登录</Button>
                            </div>
                            {this.state.isIE ? <div className="right-box" style={this.state.videoStyle}>
                                <img src={"./img/home-r-" + from + ".png"} alt="home-r" />
                            </div> : <div className="right-box" style={this.state.videoStyle}>
                                <video className="video" src={"./video/" + from + ".mp4"} autoPlay="autoplay" loop="loop" muted />
                            </div>}
                        </div>
                    </div>
                    <FooterCopy pStyle={this.state.style}></FooterCopy>
                    <div className="gk">
                        <img src="./img/bg.png" alt="home-r" />
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Index;
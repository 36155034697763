import React,{Component,Fragment} from 'react';
import { NavLink,Link } from 'react-router-dom';
// import './index.css';
import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';
import globalData from '../../configs/configs';

import linkList from './link-list';

class Head extends Component{

    constructor(props){
        super(props);
        this.state={
            comp:{
                res:null,
                linkTo:null,
                imgUrl:null
            },
            style:{
                bottom:null
            },
            isHome:props.isHome ? props.isHome : false,
            logoImgStyle:{
                'width': '31.3px',
                'height': '40px',
                'vertical-align': '-20px',
                'margin-right': '12px'
            },
            logoH1Style:{
                'font-size': '24px',
                'color': '#0D1A26',
                'font-weight': '700',
                'position': 'relative',
                'margin-top':'30px',
                'left':'0',
                'top':'0'
            }
        }
    }

    componentDidMount(){

        // 更改二级导航父元素样式
        let me=this;
        let bottom="-"+(parseInt($(".App").css("height"))-80)+"px";
        me.setState({
            style:{
                bottom
            }
        });
        resetNAVLink();
        resetLink();
        function resetNAVLink(){
            for(let i=0;i<$(".child-menu li").length;i++){
                if($($(".child-menu li")[i]).find("a").is(".childmenu-active")){
                    $($(".child-menu li")[i]).addClass("childmenu-select")
                    $($($(".child-menu li")[i]).parents("li").find("a")[0]).addClass("link-active");
                }
            }
        };

        // 渲染页面底部跳转按钮路由
        function resetLink(){

            let pathName=window.location.pathname;

            for(let j=0;j<linkList.length;j++){
                if(linkList[j].link===pathName){
                    if(j===linkList.length-2){

                        me.setState({
                            comp:{
                                res:null,
                                linkTo:null,
                                imgUrl:null
                            }
                        });
                        
                    }else{

                        if(j>1){

                            me.setState({
                                comp:{
                                    res:true,
                                    linkTo:linkList[j+1].link,
                                    imgUrl:false
                                }
                            });

                        }else{

                            me.setState({
                                comp:{
                                    res:true,
                                    linkTo:linkList[j+1].link,
                                    imgUrl:true
                                }
                            });

                        }
                    }
                }
            }
            // setTimeout(()=>{console.log(me.state);},0)
        };
        
        // 避免二级导航样式重叠
        $(".child-menu li").mouseenter(function(){
            if($(this).is(".childmenu-select")){
                $(this).css({
                "background": "#1890FF",
                "border": "1px solid rgba(0,0,0,0.09)",
                "border-radius": "2px"
                });
            }
        });
    }

    render(){
        
        const from = window._mode.from;

        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <div className="top-nav">
                            <div className="logo-box fll">
                                {this.state.isHome && 
                                <NavLink to="/index">
                                    <i>logo</i>
                                    <h1>{globalData.mainTitle}</h1>
                                </NavLink>}
                                {!this.state.isHome && 
                                <NavLink to="/index">
                                    <i style={this.state.logoImgStyle}>logo</i>
                                    <h1 style={this.state.logoH1Style}>{globalData.mainTitle}</h1>
                                </NavLink>}
                            </div>
                            <div className="nav-box flr">
                                <ul className="clearfix">
                                    <li>
                                        <NavLink to="/index" activeClassName="link-active">首页</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/law" activeClassName="link-active">法律法规</NavLink>
                                    </li>
                                    {/* <li>
                                        <NavLink to="/device" activeClassName="link-active">功能简介</NavLink>
                                    </li> */}
                                    <li>
                                        <NavLink to="/quick-jg" activeClassName="link-active">快捷入口</NavLink>
                                        <div className="child-menu">
                                            <ul>
                                                <li>
                                                    <NavLink to="/quick-jg" activeClassName="childmenu-active">监管端</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/quick-qy" activeClassName="childmenu-active">企业端</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/quick-gz" activeClassName="childmenu-active">公众端</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                    <li>
                                        <NavLink to="/small-procedure" activeClassName="link-active">小程序</NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/advantage" activeClassName="link-active">平台优势</NavLink>
                                    </li>
                                    {from !== 'gx' ? <li>
                                        <NavLink to="/case-show" activeClassName="link-active">成功案例</NavLink>
                                    </li> : ''}
                                    {from !== 'gx' ? <li>
                                        <NavLink to="/about" activeClassName="link-active">关于我们</NavLink>
                                        <div className="child-menu">
                                            <ul>
                                                <li>
                                                    <NavLink to="/about" activeClassName="childmenu-active">企业简介</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/history" activeClassName="childmenu-active">发展历程</NavLink>
                                                </li>
                                                <li>
                                                    <NavLink to="/honour" activeClassName="childmenu-active">荣誉资质</NavLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </li> : ''}
                                </ul>
                            </div>
                            {/* {this.state.comp.res && this.state.style.bottom && <div className="next-btn" style={this.state.style}>
                                {this.state.comp.imgUrl ? <Link to={this.state.comp.linkTo} className="def"></Link> : <Link to={this.state.comp.linkTo} className="oth"></Link>}
                            </div>} */}
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Head;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    height:100%;

    .about-logo{
        margin-top: 78px;
    }
    .about-logo a{
        display: inline-block;
    }
    .about-logo img{
        width: 468px;
        height: 110px;
        background-size:100% 100%;
    }
    .lr-title{
        margin-top: 122px !important;
    }
    .content-box{
        position: relative;
    }
    .l-content,.r-content{
        display: inline-block;
        vertical-align: top;
    }
    .l-content .text{
        font-family: 'MicrosoftYaHei';
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
        text-align: justify;
        line-height: 21px;
        width: 350px;
        margin-top: 31.5px !important;
    }
    .r-content{
        margin-left: 58px;

    }
    .r-content p{
        font-family: 'MicrosoftYaHei';
        font-size: 16px;
        color: #666666;
        letter-spacing: 0;
        line-height: 26px;
        margin-bottom: 2px !important;
    }
    .p-first{
        margin-top: 31.5px !important;
    }
    .r-content a{
        font-family: 'MicrosoftYaHei';
        display: inline-block;
        font-size: 16px;
        color: #1890FF;
        letter-spacing: 0;
        line-height: 26px;
        color: #1890FF !important;
        margin-top: 29px;
    }
    .about-img{
        position: absolute;
        right: 0;
        top: -70px;
    }
    @media screen and (max-height: 901px) {
        .about-logo{
            margin-top:30px;
        }
    }
    @media screen and (min-height: 1280px) {
        .fiexheight{
            height:100%;
        }
        .about-logo{
            display:inline-block;
            width:100%;
        }
        .content-box{
            display:inline-block;
            width:100%;
        }
        .fiexh{
            display:flex;
            height:100%;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
`;

export default IndexStyleComponent;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`
    background: url("./img/bg_picbeij.png") no-repeat right top rgb(237,241,245);
    width: 100%;
    height: 100%;
    background-size:49.2% 100%;

    .quickgz-content{
        margin-top: 81px;
    }
    .gz-title{
        display: inline-block;
        font-family: 'MicrosoftYaHei-Bold';
        font-size: 24px;
        color: #333333;
        letter-spacing: 0;
        font-weight: 700;
        margin-left: 16px !important;
        vertical-align: middle;
    }
    .gz-left i{
        display: inline-block;
        background-image: linear-gradient(60deg, #4480EB 0%, #04BDFD 98%);
        border-radius: 2px;
        width: 6px;
        height: 34px;
        vertical-align: middle;
    }
    .qycode-box{
        margin-top: 56px;
    }
    .qycode-box img{
        width: 784px;
        height: 400px;
        background-size: 100% 100%;
    }
    .qycode-box p{
        font-family: 'MicrosoftYaHei';
        font-size: 14px;
        color: #333333;
        text-align: justify;
        line-height: 22px;
        width: 784px;
        margin-top: 24px !important;
    }
    .gz-left,.gz-right{
        display: inline-block;
    }
    .gz-right{
        margin-left: 65px;
        vertical-align: top;
        margin-top: 42px;
    }
    @media screen and (max-height: 901px) {
        .quickgz-content{
            margin-top:24px !important;
        }
    }
    @media screen and (min-height: 1280px) {
        .quickgz-layout {
            height:100%;
        }
        .quickgz-layout>.h{
            display:flex;
            height:100%;
            align-items: center;
        }
        .quickgz-content{
            margin-top:0 !important;
        }
    }
`;

export default IndexStyleComponent;
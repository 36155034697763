import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';

class Xcxrk extends Component{

    componentDidMount(){
        $(function(){

            $(".xcxrk .item").hover(function(){
                let that=this;
                move(that);
            },function(){
                let that=this;
                $(that).find(".code-tit img").stop().animate({
                    'top':'0'
                },400);
            });

            function move(that){
                $(that).find(".code-tit img").stop().animate({
                    'top':'-627px'
                },2100,function(){
                    $(that).find(".code-tit img").animate({
                        'top':'0'
                    },2100,function(){
                        move(that);
                    });
                });
            }
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <div className="top-box">
                            <h2>小程序入口</h2>
                            <img src="./img/xcxrk.png" alt="title" />
                        </div>
                        <div className="content-box xcxrk">
                            <div className="item">
                                <div className="code-box">
                                    <img src="./img/image_qiye.png" alt="code" />
                                </div>
                                <div className="code-tit">
                                    <div className="title">食品安全监管企业端-企业端</div>
                                    <img src="./img/img_com.png" alt="qyd" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="code-box">
                                    <img src="./img/image_jg.png" alt="code" />
                                </div>
                                <div className="code-tit">
                                    <div className="title">食品安全监管云平端-监管端</div>
                                    <img src="./img/code-gov.png" alt="jgd" />
                                </div>
                            </div>
                            <div className="item">
                                <div className="code-box">
                                    <img src="./img/img_special.png" alt="code" />
                                </div>
                                <div className="code-tit">
                                    <div className="title">特种设备智慧云平台</div>
                                    <img src="./img/tzsbqy.png" alt="tzsb" />
                                </div>
                            </div>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Xcxrk;
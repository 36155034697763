import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

class History extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'#666'
          }
        }
      }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="h fiexh">
                        <div className="history-box">
                            <div className="history-logo">发展历程</div>
                            <h3 className="year year-offset-1">2013年</h3>
                            <p className="pw pw-1 text-offset-1">南开日新软件开发团队成立</p>
                            <h3 className="year year-offset-2">2014年</h3>
                            <p className="pw pw-1 text-offset-2">农产品质量安全追溯系统上线</p>
                            <h3 className="year year-offset-3">2015年</h3>
                            <p className="pw pw-1 text-offset-3">海南省水产品质量安全追溯系统上线</p>
                            <h3 className="year year-offset-4">2016年</h3>
                            <p className="pw pw-3 text-offset-4">杭州南开承盛物联科技有限公司成立；全国水产品质量安全追溯系统、食品安全检测云平台、天津市畜牧检测平台相继上线投入使用；</p>
                            <h3 className="year year-offset-5">2017年</h3>
                            <p className="pw pw-2 text-offset-5">天津放心水产品信息管理平台系统、潍坊市海洋渔业养殖企业网格化监管平台系统相继上线投入使用</p>
                            <h3 className="year year-offset-6">2018年</h3>
                            <p className="pw pw-1 text-offset-6">食品安全监管云平台上线投入使用</p>
                        </div>
                    </div>
                    <FooterCopy pStyle={this.state.style}></FooterCopy>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default History;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    .top-box{
        padding-top:48px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;

        & h2{
            color: #3366FF;
            font-size: 30px;
            font-weight: 700;
        }

        & img{
            width:393px;
            height:28px;
            margin-top:4px;
        }
    }

    .content-box{
        width:100%;
        display: flex;
        justify-content: space-between;
        margin-top:80px;
        margin-bottom:100px;

        & .item{
            width:25%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            position: relative;
            
            & .cube{
                width:200px;
                height:200px;
                background: #FFF;
                box-shadow: 0 0 24px rgba(51, 102, 255, .12);
                border-radius: 24px;
                transform: rotate(-45deg);
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;
                z-index:2;

                & img{
                    width:160px;
                    height:160px;
                    transform: rotate(45deg);
                }
            }

            & .cube.tzsb-active{
                box-shadow: 0 0 24px rgba(51, 102, 255, .32);
                transition: .25s;
            }

            & .tit{
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                color: #333333;
                margin-top:70px !important;
                user-select: none;
            }

            & .f-tit{
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #666;
                margin-top:4px !important;
                user-select: none;
            }
        }
    }

    .tzcircle-box{
        position: absolute;
        width:100%;
        height:100%;

        & .c{
            position: absolute;
            border-radius:50%;
            transition: .25s;
        }

        & .c.c1{
            width:13px;
            height:13px;
            background: #3366FF;
            opacity: 0.8;
            left:50%;
            top:50%;
        }

        & .c.c2{
            width:28px;
            height:28px;
            background: #B2DAFF;
            left:50%;
            top:50%;
        }

        & .c.c3{
            width:8px;
            height:8px;
            background: #B2DAFF;
            left:50%;
            top:50%;
        }

        & .c.c4{
            width:16px;
            height:16px;
            border: 2px solid #ADC8FF;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            left:50%;
            top:50%;

            & > div{
                width:8px;
                height:8px;
                background:#ADC8FF;
                border-radius:50%;
            }
        }

        & .c.c5{
            width:10px;
            height:10px;
            background: #ADC8FF;
            left:50%;
            top:50%;
        }

        & .c.c6{
            width:18px;
            height:18px;
            border: 2px solid #ADC8FF;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            left:50%;
            top:50%;

            & > div{
                width:10px;
                height:10px;
                border-radius:50%;
                box-sizing: border-box;
                border: 2px solid #ADC8FF;
            }
        }

        & .c.c7{
            width:10px;
            height:10px;
            background: #3366FF;
            opacity: 0.8;
            left:50%;
            top:50%;
        }
    }

`;

export default IndexStyleComponent;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    .top-box{
        padding-top:48px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;

        & h2{
            color: #3366FF;
            font-size: 30px;
            font-weight: 700;
        }

        & img{
            width:393px;
            height:28px;
            margin-top:4px;
        }
    }

    .content-box{
        display: flex;
        justify-content: space-between;
        margin-top: 72px;
        margin-bottom: 120px;
    }

    .item{
        width:30%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        box-shadow: 0 0 24px rgba(51, 102, 255, .12);
        overflow:hidden;
        cursor: pointer;
        position: relative;

        & .code-box{
            padding:70px 82px 90px 82px;
            background: #FFFFFF;
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            & img{
                width:220px;
                height:220px;
            }
        }

        & .code-tit{
            text-align: center;
            width:100%;
            font-weight: 700;
            user-select: none;
            font-size:19px;
            position: relative;

            & .title{
                padding:14px 0;
                color: #333333;
                background:rgba(255,255,255,.7);
                position: relative;
                z-index:2;
            }

            & img{
                position: absolute;
                left: 0;
                top: 0;
            }
        }
    }

`;

export default IndexStyleComponent;
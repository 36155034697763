const linkList = [{
        // 首页
        link: '/index',
    },
    {
        // 法律
        link: '/law',
    },
    // {
    //     // 功能简介
    //     link:'/device',
    // },
    {
        // 快捷入口-监管
        link: '/quick-jg',
    },
    {
        // 快捷入口-企业
        link: '/quick-qy',
    },
    {
        // 快捷入口-公众
        link: '/quick-gz',
    },
    {
        // 小程序
        link: '/small-procedure',
    },
    {
        // 平台优势
        link: '/advantage',
    },
    {
        // 成功案例
        link: '/case-show',
    },
    {
        // 关于我们
        link: '/about',
    },
    {
        // 发展历程
        link: '/history',
    },
    {
        // 荣誉资质
        link: '/honour',
    },
    {}
];

// const linkList={
//     '/index':{
//         bgImg:'def',
//         link:'/index',
//         el:<Link to="/index"></Link>
//     },
//     '/law':{
//         bgImg:'def',
//         link:'/law',
//         el:<Link to="/law"></Link>
//     },
//     '/device':{
//         bgImg:'def',
//         link:'/device',
//         el:<Link to="/device"></Link>
//     },
//     '/quick-jg':{
//         bgImg:'def',
//         link:'/quick-jg',
//         el:<Link to="/quick-jg"></Link>
//     },
//     '/quick-qy':{
//         bgImg:'def',
//         link:'/quick-qy',
//         el:<Link to="/quick-qy"></Link>
//     },
//     '/quick-jg':{
//         bgImg:'def',
//         link:'/quick-jg',
//         el:<Link to="/quick-jg"></Link>
//     },
//     '/quick-gz':{
//         bgImg:'def',
//         link:'/quick-gz',
//         el:<Link to="/quick-gz"></Link>
//     },
//     '/small-procedure':{
//         bgImg:'def',
//         link:'/small-procedure',
//         el:<Link to="/small-procedure"></Link>
//     },
//     '/advantage':{
//         bgImg:'def',
//         link:'/advantage',
//         el:<Link to="/advantage"></Link>
//     },
//     '/case-show':{
//         bgImg:'def',
//         link:'/case-show',
//         el:<Link to="/case-show"></Link>
//     },
//     '/about':{
//         bgImg:'def',
//         link:'/about',
//         el:<Link to="/about"></Link>
//     },
//     '/history':{
//         bgImg:'def',
//         link:'/history',
//         el:<Link to="/history"></Link>
//     },
//     '/honour':{
//         bgImg:'def',
//         link:'/honour',
//         el:<Link to="/honour"></Link>
//     }
// };

export default linkList;
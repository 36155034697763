import styled from 'styled-components';

const IndexStyleComponent = styled.div `
    position: relative;
    z-index: 100;
    background:#fff;

    @font-face{
        font-family: SourceHanSansCN-Bold;
        src:url();

        font-family: SourceHanSansCN-Heavy;
        src:url();

        font-family: SourceHanSansCN-Medium;
        src:url();
    }

    .top-nav{
        height: 82px;
        line-height: 82px;
        background: #fff;
        position: relative;
    }
    .logo-box h1,.logo-box i{
        display: inline-block;
    }
    .logo-box h1{
        display: flex;
        align-items: center;
        height:100%;

        &>img{
            width:432px;
            display: inline-block;
            vertical-align: -5px;
        }
    }
    .logo-box i{
        width: 245px;
        height:48px;
        background: url("./img/logo_sc@2x.png");
        background-size:100% 100%;
        text-indent: -9999%;
        vertical-align: -10px;
        margin-right: 12px;
    }
    .logo-box a{
        display: block;
        height: 72px;
    }
`;

export default IndexStyleComponent;
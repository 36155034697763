import React,{Component,Fragment} from 'react';
// import './index.css';

import IndexStyleComponent from './indexStyleComponent';

import QueueAnim from 'rc-queue-anim';

import { NavLink,Link } from 'react-router-dom';

import globalData from '../../configs/configs';

import $ from 'jquery';

const MODE=window._mode;
const target=MODE.buttonTarget === '1' ? '_self' : MODE.buttonTarget === '2' ? '_blank' : '_self'

class MainTop extends Component{

    componentDidMount(){

    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <QueueAnim delay={300} className="queue-simple" type="right">
                            <div className="l" key="1">
                                <div className="l-box">
                                    <img className="logo" src="./img/logo_foodsafety.png" alt="logo"/>
                                </div>
                                <div className="title1">
                                    <img className="title-l" src="./img/title-l.png" alt="title"/>
                                </div>
                                <div className="title2">
                                    <img className="title-l" src="./img/title-l2.png" alt="title"/>
                                </div>
                                <div className="l-content">
                                    <p className="content">“食品安全监管云平台” 是由杭州南开承盛物联科技有限公司开发的数字化监管平台。平台把生产（含小作坊）、流通、餐饮等各种业态纳为一体，以追溯为核心功能，以落实企业主体责任为落脚点，形成全链式食品安全数字化监管，创新监管手段，提升监管时效性。</p>
                                </div>
                                <div className="btn-box ex-margin">
                                    <a className="button" href={MODE.foodEnterpriseButtonUrl || 'javascript:;'} target={target}>企业登录</a>
                                    <a className="button b-ml" href={MODE.foodGovButtonUrl || 'javascript:;'} target={target}>监管登录</a>
                                </div>
                            </div>
                        </QueueAnim>
                        <QueueAnim delay={500} className="queue-simple" type="left">
                            <div className="r" key="2">
                                <div className="title3">
                                    <img className="title-r" src="./img/title-r.png" alt="title"/>
                                </div>
                                <div className="title4">
                                    <img className="title-r" src="./img/title-r2.png" alt="title"/>
                                </div>
                                <div className="r-content">
                                    <p>随着特种设备安全运行形势的不断变化,特种设备监督检验部门对特种设备的安全监管工作的要求也越来越高。特种设备运行的安全与否,既关系到人民生命财产安全,也关系到整个社会的发展。为了促进经济发展和社会进步,我们必须增强安全责任意识。</p>
                                </div>
                                <div className="r-box">
                                    <img className="logo" src="./img/tzlogo.png" alt="logo"/>
                                    <div className="btn-box">
                                        <a className="button" href={MODE.deviceEnterpriseButtonUrl || 'javascript:;'} target={target}>企业登录</a>
                                        <a className="button b-ml" href={MODE.deviceGovButtonUrl || 'javascript:;'} target={target}>监管登录</a>
                                    </div>
                                </div>
                            </div>
                        </QueueAnim>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default MainTop;
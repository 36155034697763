import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    .top-box{
        padding-top:48px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;

        & h2{
            color: #3366FF;
            font-size: 30px;
            font-weight: 700;
        }

        & img{
            width:393px;
            height:28px;
            margin-top:4px;
        }
    }

    .content-box{
        margin-top:80px;
        width:100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom:100px;

        & .item{
            width:25%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            & .circle{
                width:200px;
                height:200px;
                background: #FFF;
                border-radius:50%;
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                cursor: pointer;
                margin-bottom:16px;
                position: relative;

                & img{
                    width:100%;
                    height:100%;
                    padding:40px;
                    background:#fff;
                    border-radius:50%;
                    z-index:2;
                }

                & .circle-box{
                    position: absolute;
                    left:0;
                    top:0;
                    width:100%;
                    height:100%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
    
                    & .cir1{
                        position: absolute;
                        width:0;
                        height:0;
                        left:50%;
                        top:50%;
                        border-radius:50%;
                        opacity:.4;
                    }

                    & .cir2{
                        position: absolute;
                        width:0;
                        height:0;
                        left:50%;
                        top:50%;
                        border-radius:50%;
                        opacity:.2;
                    }

                    & .move{
                        animation: move 5s linear infinite;
                        animation-fill-mode: forwards;
                    }

                    & .move2{
                        animation: move2 5s linear infinite;
                        animation-fill-mode: forwards;
                    }

                    & .move.paused{
                        animation-play-state:paused;
                        -webkit-animation-play-state:paused; /* Safari 和 Chrome */
                    }

                    @keyframes move {
                        to{
                            transform: rotate(360deg);
                        }
                    }

                    @keyframes move2 {
                        to{
                            transform: rotate(-360deg);
                        }
                    }
                }
            }

            & .tit{
                color:#333;
                width:100%;
                font-size: 20px;
                line-height: 28px;
                text-align: center;
                margin-top:16px;
                font-weight: 700;
                user-select: none;
            }

            & .f-tit{
                font-size: 16px;
                line-height: 22px;
                text-align: center;
                color: #666;
                width:100%;
                margin-top:4px;
                user-select: none;
            }
        }

        & .item.mt{
            margin-top:55px;
        }


        .b{
            box-shadow: 0 0 24px rgba(51, 102, 255, .12);
            transition: .4s;
        }
        .b:hover{
            box-shadow: 0 5px 24px rgba(51, 102, 255, .5);
            transition: .4s;
        }

        .o{
            box-shadow: 0 0 24px rgba(255, 191, 0, .16);
            transition: .4s;
        }
        .o:hover{
            box-shadow: 0 5px 12px rgba(255,160,2,.4);
            transition: .4s;
        }

        .r{
            box-shadow: 0 0 24px rgba(242, 86, 86, .16);
            transition: .4s;
        }
        .r:hover{
            box-shadow: 0 5px 12px rgba(242, 86, 86, .5);
            transition: .4s;
        }

        .g{
            box-shadow: 0 0 24px rgba(115, 196, 39, .16);
            transition: .4s;
        }
        .g:hover{
            box-shadow: 0 5px 16px rgba(115, 196, 39, .5);
            transition: .4s;
        }
    }
`;

export default IndexStyleComponent;
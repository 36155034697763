import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';

class Devicead extends Component{

    componentDidMount(){

        // 点击切换active图源
        let oLi=$(".device-nav-box li");
        let oVideo=$(".video-box video");
        let flag=true;

        oLi.on("click",function(){

            if(flag){
                
                for(let i=0;i<oLi.length;i++){
                    let _this=$(oLi[i]).find("img");
                    if(_this.attr("src").split("-")[1]){
                        _this.attr("src",_this.attr("src").split("-")[0]+".png");
                    }
                }

                let _activeElm=$(this).find("img");
                _activeElm.attr("src","."+_activeElm.attr("src").split(".")[1]+"-active.png");

                if($(this).index()%2===0){
                    oVideo.attr("src","./video/test-3.ogg");
                }else{
                    oVideo.attr("src","./video/test-2.ogg");
                }

                // 防抖
                flag=false;
                setTimeout(()=>flag=true,1000);
            }
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="h">
                        <div className="video-box">
                            <div className="mac">
                                <div>
                                    <video src="./video/test-3.ogg" controls="controls">
                                        当前浏览器版本过低不支持视频预览，请升级！
                                    </video>
                                </div>
                            </div>
                            <div class="phone">
                                <div>
                                    <video src="./video/test-3.ogg" controls="controls">
                                        当前浏览器版本过低不支持视频预览，请升级！
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className="device-nav-box">
                            <ul className="clearfix mask-line">
                                <li>
                                    <a href="javascript:;">
                                        <img src="./img/data-active.png" alt="数据备案" />
                                        <span>数据备案</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img src="./img/doubt.png" alt="可疑数据" />
                                        <span>可疑数据</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img src="./img/ticket.png" alt="索证索票" />
                                        <span>索证索票</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img src="./img/tongji.png" alt="统计研判" />
                                        <span>统计研判</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img src="./img/genzong.png" alt="追踪溯源" />
                                        <span>追踪溯源</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img src="./img/shuju.png" alt="数据服务" />
                                        <span>数据服务</span>
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:;">
                                        <img src="./img/warning.png" alt="安全预警" />
                                        <span>安全预警</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Devicead;
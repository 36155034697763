import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink,Link } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import Footer from '../../components/footer';
import IndexStyleComponent from './indexStyleComponent';

class Error extends Component{
    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="h fixdheight">
                        <div className="error-box">
                            <h2>哎呀！您所访问的页面飘出地球了~</h2>
                        </div>
                    </div>
                    <Footer></Footer>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Error;
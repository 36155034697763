import styled from 'styled-components';

const IndexStyleComponent = styled.div`
    .video-box{
        margin-top: 74px;
    }
    .video-box .mac{
        background: url("./img/mac.png")no-repeat center center #EEEEEE;
        width: 876px;
        height: 469px;
        position: relative;
        display: inline-block;
    }
    .video-box .phone{
        width: 225px;
        height: 448px;
        position: relative;
        display: inline-block;
        background: url("./img/phone.png") no-repeat;
        border-radius: 10px;
        vertical-align: top;
        margin-left: 69px;
    }
    .video-box .mac div{
        width: 559.5px;
        height: 349.7px;
        position: absolute;
        left: 158.3px;
        top: 40.1px;
    }
    .video-box video{
        width: 100%;
        height: 100%;
        object-fit: fill;
        outline: none;
    }
    .video-box .phone div{
        position: absolute;
        left: 12px;
        top: 52px;
        vertical-align: top;
        width: 198px;
        height: 345px;
    }

    .device-nav-box{
        margin-top: 24px;
        position: relative;
        z-index: 10;
        user-select: none;
    }
    .device-nav-box .mask-line::before{
        position: absolute;
        display: inline-block;
        width: 100%;
        height: 1px;
        background: #1890FF;
        left: 0;
        top: 50px;
        z-index: -1;
    }
    .device-nav-box li{
        float: left;
        margin-right: 40px !important;
        width: 120px;
    }
    .device-nav-box li:first-child{
        margin-left: 70px !important;
    }
    .device-nav-box li a{
        width: 100%;
        display: inline-block;
        text-align: center;
        cursor: pointer;
    }
    .device-nav-box li img{
        width: 100px;
        height: 100px;
        background-size:100% 100%;
        display: inline-block;
    }
    .device-nav-box li span{
        display: inline-block;
        margin-top: 16px;
        font-family: 'MicrosoftYaHei';
        font-size: 24px;
        color: #000000;
        letter-spacing: 0;
        line-height: 24px;
        width: 100%;
        font-weight: 400;
    }
`;

export default IndexStyleComponent;
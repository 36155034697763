import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';
import IndexStyleComponent from './indexStyleComponent';

class MainFooter extends Component{
    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <div className="content-box">
                            <div className="item">
                                <img src="./img/image_wechat.png" alt="other" />
                                <p className="tit">南开承盛微信公众号</p>
                            </div>
                            <div className="item">
                                <img src="./img/wxkf.png" alt="other" />
                                <p className="tit">微信客服</p>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <div className="h">
                            <p className="item bdb">
                                <p className="tel">技术支持电话：4009696592</p>
                            </p>
                            <p className="item expd1">
                                <p className="mr">版权所有</p>
                                <p className="mr">@2020杭州南开承盛物联科技有限公司</p>
                                <p className="mr">
                                    <a href="http://beian.miit.gov.cn/publish/query/indexFirst.action" target="_blank">浙ICP备&nbsp;&nbsp;l16028625号-2</a>
                                </p>
                                <p>
                                    <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002093" target="_blank">浙公安网备&nbsp;&nbsp;33010902002093</a>
                                </p>
                            </p>
                            <p className="item expd2">
                                <p>建议使用谷歌浏览器进行访问，建议分辨率1920*1080</p>
                            </p>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default MainFooter;
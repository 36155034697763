import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';

class Tzsb extends Component{

    componentDidMount(){
        $(function(){
            $(".tzsb .cube").hover(function(){
                
                let c1=$(this).parents('.item').find('.c1');
                let c2=$(this).parents('.item').find('.c2');
                let c3=$(this).parents('.item').find('.c3');
                let c4=$(this).parents('.item').find('.c4');
                let c5=$(this).parents('.item').find('.c5');
                let c6=$(this).parents('.item').find('.c6');
                let c7=$(this).parents('.item').find('.c7');

                $(this).addClass('tzsb-active');

                c1.css({
                    'left':'70px',
                    'top':'-25px'
                });

                c2.css({
                    'left':'25px',
                    'top':'-9px'
                });

                c3.css({
                    'left':'25px',
                    'top':'25px'
                });

                c4.css({
                    'left':'235px',
                    'top':'-12px'
                });

                c5.css({
                    'left':'240px',
                    'top':'20px'
                });

                c6.css({
                    'left':'205px',
                    'top':'200px'
                });

                c7.css({
                    'left':'240px',
                    'top':'195px'
                });
                
            },function(){
                let c1=$(this).parents('.item').find('.c1');
                let c2=$(this).parents('.item').find('.c2');
                let c3=$(this).parents('.item').find('.c3');
                let c4=$(this).parents('.item').find('.c4');
                let c5=$(this).parents('.item').find('.c5');
                let c6=$(this).parents('.item').find('.c6');
                let c7=$(this).parents('.item').find('.c7');

                $(this).removeClass('tzsb-active');

                c1.css({
                    'left':'50%',
                    'top':'50%'
                });

                c2.css({
                    'left':'50%',
                    'top':'50%'
                });

                c3.css({
                    'left':'50%',
                    'top':'50%'
                });

                c4.css({
                    'left':'50%',
                    'top':'50%'
                });

                c5.css({
                    'left':'50%',
                    'top':'50%'
                });

                c6.css({
                    'left':'50%',
                    'top':'50%'
                });

                c7.css({
                    'left':'50%',
                    'top':'50%'
                });
            });
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <div className="top-box">
                            <h2>特种设备智慧云平台</h2>
                            <img src="./img/tzsbtitle.png" alt="title" />
                        </div>
                        <div className="content-box tzsb">
                            <div className="item">
                                <div className="cube">
                                    <img src="./img/shebei.png" alt="tzsb" />
                                </div>
                                <p className="tit">设备管理</p>
                                <p className="f-tit">八大特种设备管理</p>
                                <div className="tzcircle-box">
                                    <div className="c c1"></div>
                                    <div className="c c2"></div>
                                    <div className="c c3"></div>
                                    <div className="c c4">
                                        <div></div>
                                    </div>
                                    <div className="c c5"></div>
                                    <div className="c c6">
                                        <div></div>
                                    </div>
                                    <div className="c c7"></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="cube">
                                    <img src="./img/gaozhi.png" alt="tzsb" />
                                </div>
                                <p className="tit">施工告知</p>
                                <p className="f-tit">使用单位、施工单位记录</p>
                                <div className="tzcircle-box">
                                    <div className="c c1"></div>
                                    <div className="c c2"></div>
                                    <div className="c c3"></div>
                                    <div className="c c4">
                                        <div></div>
                                    </div>
                                    <div className="c c5"></div>
                                    <div className="c c6">
                                        <div></div>
                                    </div>
                                    <div className="c c7"></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="cube">
                                    <img src="./img/worker.png" alt="tzsb" />
                                </div>
                                <p className="tit">人员管理</p>
                                <p className="f-tit">人员持证信息、操作设备</p>
                                <div className="tzcircle-box">
                                    <div className="c c1"></div>
                                    <div className="c c2"></div>
                                    <div className="c c3"></div>
                                    <div className="c c4">
                                        <div></div>
                                    </div>
                                    <div className="c c5"></div>
                                    <div className="c c6">
                                        <div></div>
                                    </div>
                                    <div className="c c7"></div>
                                </div>
                            </div>
                            <div className="item">
                                <div className="cube">
                                    <img src="./img/tongjichaxun.png" alt="tzsb" />
                                </div>
                                <p className="tit">查询统计</p>
                                <p className="f-tit">设备设备、检验查询</p>
                                <div className="tzcircle-box">
                                    <div className="c c1"></div>
                                    <div className="c c2"></div>
                                    <div className="c c3"></div>
                                    <div className="c c4">
                                        <div></div>
                                    </div>
                                    <div className="c c5"></div>
                                    <div className="c c6">
                                        <div></div>
                                    </div>
                                    <div className="c c7"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Tzsb;
import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

import Swiper from 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';

class CaseShow extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'rgba(255,255,255,0.87)'
          }
        }
      }

    componentDidMount(){
        new Swiper ('.swiper-container', {
            direction: 'vertical',
            loop: true,  //循环
            speed:2000,
            autoplay : {
                delay:4500
            },
            pagination: {  //分页器
                el: '.swiper-pagination'
            }
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="case-box">
                        <div className="h">
                            <div className="case-content">
                                <div className="case-map">
                                    <p>食品安全监管云平台目前已在浙江、安徽、广西、山东、辽宁、江苏、四川等省及全国其他地区的百余个县市上线使用。目前入驻企业上万家，日均生成票据十万余条。</p>
                                    <img src="./img/map.png" alt="map" />
                                </div>
                                <div className="jp-box">
                                    <div className="swiper-container">
                                        <div className="swiper-wrapper">
                                            <div className="swiper-slide">
                                                <ul>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/SFpdJZhIMlUx2zjrzXHqSA" target="_blank">
                                                            <img src="./img/img_first.png" alt="jp-banner" />
                                                            <span>
                                                                <i>南开承盛助力总局小作坊监管工作现场会，推动发展食品生产智慧监管新模式</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/G-ZjcokaF2CrS2GmwnRnCA" target="_blank">
                                                            <img src="./img/img_second.png" alt="jp-banner" />
                                                            <span>
                                                                <i>“融杭”—食品安全工作先行 南开承盛助力黄山市歙县市场监督管理局打响食品安全第一枪</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/vEV2kdSOen6IMI42T0EVXg" target="_blank">
                                                            <img src="./img/img_third.png" alt="jp-banner" />
                                                            <span>
                                                                <i>黄山市市场监督管理局局长带队“融杭“，再次考察南开日新集团</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/uQ4zewHkI5fIz5-VV7_1yg" target="_blank">
                                                            <img src="./img/img_four.png" alt="jp-banner" />
                                                            <span>
                                                                <i>南开承盛助力浙江省食品生产监管工作现场会，推动发展食品生产智慧监管新模式</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="swiper-slide">
                                                <ul>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/6CKogLLxsLN9Mg6MAhAqXA" target="_blank">
                                                            <img src="./img/img_five.png" alt="jp-banner" />
                                                            <span>
                                                                <i>南开承盛受邀参加合肥市食品安全智慧监管培训暨工作推进</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/UX3Ejd_rwIkB5laXJ1cy9w" target="_blank">
                                                            <img src="./img/img_six.png" alt="jp-banner" />
                                                            <span>
                                                                <i>南开承盛助力临安区召开特殊食品生产经营单位行政约谈暨业务培训会议</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/_04TgDnEOujK6rTBnPrmCQ" target="_blank">
                                                            <img src="./img/img_seven.png" alt="jp-banner" />
                                                            <span>
                                                                <i>喜讯连连，杭州市及市临安区、河池市宜州区市场监督管理局领导莅临我司考察指导</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a href="https://mp.weixin.qq.com/s/J91o74dAp6LfdIRSJmqWyA" target="_blank">
                                                            <img src="./img/img_seighth.png" alt="jp-banner" />
                                                            <span>
                                                                <i>南开承盛助力临安区市场监管创新，打造食品安全智慧管理新模式</i>
                                                            </span>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <FooterCopy pStyle={this.state.style}></FooterCopy>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default CaseShow;
import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';

class Cloud extends Component{

    componentDidMount(){
        $(function(){

            function randomNum(minNum,maxNum){
                switch(arguments.length){ 
                    case 1: 
                        return parseInt(Math.random()*minNum+1,10); 
                        break; 
                    case 2: 
                        return parseInt(Math.random()*(maxNum-minNum+1)+minNum,10); 
                        break; 
                    default: 
                        return 0; 
                        break; 
                } 
            }

            $(".cloud .item .circle").hover(function(){
                let that=this;
                // 球的数量
                let ball1Num=randomNum(2,4);
                let ball2Num=randomNum(3,6);

                let cir1=$(that).find('.cir1');
                let cir2=$(that).find('.cir2');

                if($(that).is(".b")){
                    cir1.css({
                        'border': '2px solid #349CFD',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                    cir2.css({
                        'border': '4px solid #349CFD',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                }else if($(that).is(".o")){
                    cir1.css({
                        'border': '2px solid #FDA91F',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                    cir2.css({
                        'border': '4px solid #FF9F00',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                }else if($(that).is(".r")){
                    cir1.css({
                        'border': '2px solid #F26A6A',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                    cir2.css({
                        'border': '4px solid #F25656',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                }else if($(that).is(".g")){
                    cir1.css({
                        'border': '2px solid #74B929',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                    cir2.css({
                        'border': '4px solid #9FDB5E',
                        'box-shadow': '0 0 24px rgba(51, 102, 255, .12)'
                    });
                }

                for(let i=0;i<ball1Num;i++){
                    // 球的大小
                    let cirBall=randomNum(7,11);

                    let cir1Div=getPos({
                        num:randomNum(1,8),
                        cirBall,
                        opacity:(randomNum(60,100)+''),
                        backaground:getBackground(that),
                    });

                    cir1Div.appendTo($(that).find('.cir1'));
                }

                for(let i=0;i<ball2Num;i++){
                    let cirBall=randomNum(10,20);
                    let cir2Div=getPos({
                        num:randomNum(1,8),
                        cirBall,
                        opacity:(randomNum(60,100)+''),
                        backaground:getBackground(that),
                    });

                    cir2Div.appendTo($(that).find('.cir2'));
                }

                cir1.css('animation-duration',(randomNum(4000,7000)/1000)+'s').stop().animate({
                    'width':'216px',
                    'height':'216px',
                    'left':'-8px',
                    'top':'-8px',
                },200,function(){
                    cir1.removeClass('paused').addClass('move')
                });

                cir2.css('animation-duration',(randomNum(3000,6000)/1000)+'s').stop().animate({
                    'width':'240px',
                    'height':'240px',
                    'left':'-20px',
                    'top':'-20px',
                },200,function(){
                    cir2.removeClass('paused').addClass('move2')
                });
            },function(){
                let that=this;
                let cir1=$(that).find('.cir1');
                let cir2=$(that).find('.cir2');
                cir1.addClass('paused');
                cir2.addClass('paused');

                cir1.stop().animate({
                    'width':'0',
                    'height':'0',
                    'left':'50%',
                    'top':'50%'
                },200);

                cir2.stop().animate({
                    'width':'0',
                    'height':'0',
                    'left':'50%',
                    'top':'50%'
                },200);

                cir1.empty();
                cir2.empty();
            });

            // 随机球的位置
            function getPos(options){
                if(options.num === 1){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'calc(50% - '+(options.cirBall/2+1)+'px)',
                        'top':'-'+(options.cirBall/2+1)+'px',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }else if(options.num === 2){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'calc(80% - '+(options.cirBall/2+1)+'px)',
                        'top':'calc(9% - '+(options.cirBall/2+1)+'px)',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }else if(options.num === 3){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'calc(100% - '+(options.cirBall/2-1)+'px)',
                        'top':'calc(50% - '+(options.cirBall/2+1)+'px)',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }else if(options.num === 4){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'calc(80% - '+(options.cirBall/2+1)+'px)',
                        'top':'calc(90% - '+(options.cirBall/2-1)+'px)',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }else if(options.num === 5){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'calc(50% - '+(options.cirBall/2+1)+'px)',
                        'top':'calc(100% - '+(options.cirBall/2-1)+'px)',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }else if(options.num === 6){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'calc(20% - '+(options.cirBall/2+1)+'px)',
                        'top':'calc(90% - '+(options.cirBall/2-1)+'px)',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }else if(options.num === 7){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'-'+(options.cirBall/2+1)+'px',
                        'top':'calc(50% - '+(options.cirBall/2+1)+'px)',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }else if(options.num === 8){
                    return $("<div></div>").css({
                        'position': 'absolute',
                        'width':options.cirBall+'px',
                        'height':options.cirBall+'px',
                        'left':'calc(20% - '+(options.cirBall/2-1)+'px)',
                        'top':'calc(9% - '+(options.cirBall/2-1)+'px)',
                        'background':options.backaground,
                        'border-radius':'50%',
                        'opacity':options.opacity
                    });
                }
            }

            // 去随机颜色
            function getBackground(that){
                if($(that).is(".b")){
                    return randomNum(1,2) === 1 ? '#3366FF' : '#ADC8FF';
                }else if($(that).is(".o")){
                    return randomNum(1,2) === 1 ? '#FFBF00' : '#FCDEAC';
                }else if($(that).is(".r")){
                    return randomNum(1,2) === 1 ? '#FF8B85' : '#FFA39E';
                }else if($(that).is(".g")){
                    return randomNum(1,2) === 1 ? '#9CC476' : '#91C462';
                }
            }

        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <div className="top-box">
                            <h2>食品安全监管云平台</h2>
                            <img src="./img/saytitle.png" alt="title" />
                        </div>
                        <div className="content-box cloud">
                            <div className="item">
                                <div className="circle b">
                                    <img src="./img/company_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">企业资质</p>
                                <p className="f-tit">企业风险等级、证照信息</p>
                            </div>
                            <div className="item">
                                <div className="circle o">
                                    <img src="./img/worker_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">从业人员</p>
                                <p className="f-tit">从业人员管理、考核</p>
                            </div>
                            <div className="item">
                                <div className="circle r">
                                    <img src="./img/supply_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">供应商管理</p>
                                <p className="f-tit">供应商证照、法人、地址</p>
                            </div>
                            <div className="item">
                                <div className="circle g">
                                    <img src="./img/process_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">生产过程管理</p>
                                <p className="f-tit">生产过程全控制、生产报备</p>
                            </div>
                            <div className="item mt">
                                <div className="circle b">
                                    <img src="./img/product_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">产品管理</p>
                                <p className="f-tit">产品条形码、保质期、规格型号</p>
                            </div>
                            <div className="item mt">
                                <div className="circle r">
                                    <img src="./img/sale_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">销售管理</p>
                                <p className="f-tit">销售对象、销售商品、交易日期</p>
                            </div>
                            <div className="item mt">
                                <div className="circle o">
                                    <img src="./img/caigou_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">采购管理</p>
                                <p className="f-tit">采购商品商品、采购票据</p>
                            </div>
                            <div className="item mt">
                                <div className="circle g">
                                    <img src="./img/video_index.png" alt="qyzz" />
                                    <div className="circle-box">
                                        <div className="cir1"></div>
                                        <div className="cir2"></div>
                                    </div>
                                </div>
                                <p className="tit">阳光云视</p>
                                <p className="f-tit">视频直播、AI智能监控预警</p>
                            </div>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Cloud;
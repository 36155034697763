import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

import Swiper from 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';

import $ from 'jquery';

import globalData from '../../configs/configs';

class QuickJg extends Component{

    constructor(){
        super();
        this.state={
            style:{
                height:null,
                color:'#333'
            }
        }
    }
    
    componentDidMount(){
        let height=(parseInt($(".App").css("height"))-82)+"px";
        this.setState({
            style:{
                height
            }
        });
        new Swiper ('.swiper-container', {
            loop: true,  //循环
            speed:1800,
            autoplay : {
                delay:4000
            },
            pagination: {  //分页器
                el: '.swiper-pagination'
            }
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <img src="./img/img_jg.png" className="jg-qk-bg" />
                    <div className="quick-box">
                        <div className="h">
                            <div className="jg-quick-content">
                                <img src="./img/logo_jg.png" alt="logo_jg" />
                                <h2>监管端</h2>
                                <p>改变以往点式监管为链式监管，从一个端口可以核查全区从生产到流通到餐饮全过程生产经营信息。监管单位可随时在线核对入网食品生产经营企业的相关生产经营信息，通过查阅、预警、统计、分析等系统功能，及时、准确锁定问题食品或问题企业，采取有效处置措施，控制事态发展，进一步提升监管效率和食品安全风险防范水平。</p>
                                {/* <Button type="primary" shape="round" className="qy-login ant-btn">网页端登录</Button> */}
                                <a href={globalData.jgUrl} className="qy-login" target="_blank">网页端登录</a>
                                <span>扫码使用小程序</span>
                                <img src={globalData.jgMiniIcoPath} alt="ma_jg" />
                            </div>
                            <div className="jg-quick-swiper">
                                <div className="swiper-container">
                                    <div className="swiper-wrapper banner-box">
                                        <div className="swiper-slide">
                                            <a href="javascript:;" title="当食品污染事件、食源性疾病事件等发生时，通过应急直报功能，监管单位能够第一时间得知情况并迅速处理。">
                                                <img src="./img/yjzb.png" alt="yjzb" />
                                                <div className="oc-mask">
                                                    <h3>应急直报</h3>
                                                    <p>当食品污染事件、食源性疾病事件等发生时，通过应急直报功能，监管单位能够第一时间得知情况并迅速处理。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="统一管理辖区内各类型企业，方便查询企业基础信息、备案信息、生产台账信息等，真正做到随时随地监管。">
                                                <img src="./img/qygl.png" alt="qygl" />
                                                <div className="oc-mask">
                                                    <h3>企业管理</h3>
                                                    <p>统一管理辖区内各类型企业，方便查询企业基础信息、备案信息、生产台账信息等，真正做到随时随地监管。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="监督检查分为日常监督检查和主体责任核查，通过该功能对企业进行食品生产、流通或餐饮服务检查等。">
                                                <img src="./img/yjzb.png" alt="yjzb" />
                                                <div className="oc-mask">
                                                    <h3>监督检查</h3>
                                                    <p>监督检查分为日常监督检查和主体责任核查，通过该功能对企业进行食品生产、流通或餐饮服务检查等。</p>
                                                </div>
                                            </a>
                                        </div>
                                        <div className="swiper-slide" title="当食品污染事件、食源性疾病事件等发生时，通过应急直报功能，监管单位能够第一时间得知情况并迅速处理。">
                                            <a href="javascript:;">
                                                <img src="./img/yjzb.png" alt="yjzb" />
                                                <div className="oc-mask">
                                                    <h3>应急直报</h3>
                                                    <p>当食品污染事件、食源性疾病事件等发生时，通过应急直报功能，监管单位能够第一时间得知情况并迅速处理。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="统一管理辖区内各类型企业，方便查询企业基础信息、备案信息、生产台账信息等，真正做到随时随地监管。">
                                                <img src="./img/qygl.png" alt="qygl" />
                                                <div className="oc-mask">
                                                    <h3>企业管理</h3>
                                                    <p>统一管理辖区内各类型企业，方便查询企业基础信息、备案信息、生产台账信息等，真正做到随时随地监管。</p>
                                                </div>
                                            </a>
                                            <a href="javascript:;" title="监督检查分为日常监督检查和主体责任核查，通过该功能对企业进行食品生产、流通或餐饮服务检查等。">
                                                <img src="./img/yjzb.png" alt="yjzb" />
                                                <div className="oc-mask">
                                                    <h3>监督检查</h3>
                                                    <p>监督检查分为日常监督检查和主体责任核查，通过该功能对企业进行食品生产、流通或餐饮服务检查等。</p>
                                                </div>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <FooterCopy pStyle={this.state.style}></FooterCopy>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default QuickJg;
import React,{Component,Fragment} from 'react';
// import './index.css';

import MainHead from '../../components/mainHead';
import MainTop from '../main-top/index';
import YwTp from '../ywtp/index';
import Cloud from '../cloud/index';
import Tzsb from '../tzsb/index';
import Xcxrk from '../xcxrk/index';
import IndexStyleComponent from './indexStyleComponent';
import MainFooter from '../../components/mainFooter';

import $ from 'jquery';

import globalData from '../../configs/configs';

class Preview extends Component{

    componentDidMount(){
        $(function(){
            $('body').css({
                'overflow-y':'scroll',
                'overflow-x':'hidden'
            });
        });
    };

    render(){
        return (
            <Fragment>
                <IndexStyleComponent className="main-page">
                    <MainHead />
                    <MainTop />
                    <YwTp />
                    <Cloud />
                    <Tzsb />
                    <Xcxrk />
                    <MainFooter />
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Preview;
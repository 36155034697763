import 'babel-polyfill';
import React,{Component, Fragment} from 'react';
// import './App.css';
import { Button, Radio, Icon } from 'antd';

// 引入路由工具组件
import { Route, Redirect, Switch, withRouter } from 'react-router-dom';

import Preview from './pages/preview/index';
import Index from './pages/index';
import Law from './pages/law/index';
import Device from './pages/device/index';
import QuickQy from './pages/quick-qy/index';
import QuickJg from './pages/quick-jg/index';
import QuickGz from './pages/quick-gz/index';
import SmallProcedure from './pages/small-procedure/index';
import Advantage from './pages/advantage/index';
import CaseShow from './pages/case-show/index';
import About from './pages/about/index';
import History from './pages/history/index';
import Honour from './pages/honour/index';
import Error from './pages/error/index';

class App extends Component {

  state = {
    size: 'large',
  };

  handleSizeChange = e => {
    this.setState({ size: e.target.value });
  };

  render(){
    const { size } = this.state;
    const from = window._mode.from;
      return (
        <Fragment>
          <div className="App" >
            <Switch>
              <Redirect from="/" to="/index" exact></Redirect>
              <Route path="/index" component={Index} exact></Route>
              <Route path="/preview" component={Preview} exact></Route>
              <Route path="/law" component={Law} exact></Route>
              {/* <Route path="/device" component={Device} exact></Route> */}
              <Route path="/quick-jg" component={QuickJg} exact></Route>
              <Route path="/quick-qy" component={QuickQy} exact></Route>
              <Route path="/quick-gz" component={QuickGz} exact></Route>
              <Route path="/small-procedure" component={SmallProcedure} exact></Route>
              <Route path="/advantage" component={Advantage} exact></Route>
              {from !== 'gx' ? <Route path="/case-show" component={CaseShow} exact></Route> : null}
              {from !== 'gx' ? <Route path="/about" component={About} exact></Route> : null}
              {from !== 'gx' ? <Route path="/history" component={History} exact></Route> : null}
              {from !== 'gx' ? <Route path="/honour" component={Honour} exact></Route> : null}
              {/* <Route component={Error}></Route> */}
            </Switch>
          </div>
        </Fragment>
      );
  };
};

export default App;
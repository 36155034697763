import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

import globalData from '../../configs/configs';

class SmallProcedure extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'rgba(255,255,255,0.87)'
          }
        }
      }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="small-box">
                        <div className="h">
                            <div className="phone-box">
                                <img src="./img/miniprogram.png" alt="miniprogram" />
                            </div>
                            <div className="small-content">
                                <p className="small-title">政府监管企业报备</p>
                                <p className="small-tips">
                                    <i></i>
                                    <span>来源可追</span>
                                    <i></i>
                                    <span>去向可查</span>
                                    <i></i>
                                    <span>实时监管</span>
                                    <i></i>
                                    <span>落实责任</span>
                                </p>
                                <p className="small-code">
                                    <div>
                                        <img src={globalData.jgMiniIcoPath} alt="miniprogram" />
                                        <span>扫码体验监管端</span>
                                    </div>
                                    <div>
                                        <img src={globalData.qyMiniIcoPath} alt="miniprogram" />
                                        <span>扫码体验企业端</span>
                                    </div>
                                </p>
                            </div>
                        </div>
                        <FooterCopy pStyle={this.state.style}></FooterCopy>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default SmallProcedure;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`
    width: 100%;
    background: #EDF1F5;
    position: absolute;
    left:0;
    bottom:0;
    z-index:5;

    & .h{
        position: relative;
    }
    & .cp-box{
        margin-top: 80px;
        margin-left: 55px;
        display: inline-block;
    }

    .cp-box li{
        margin-bottom: 18px !important;
    }
    .cp-box li i{
        display: inline-block;
        width: 18px;
        height: 18px;
        vertical-align: -3px;
        margin-right: 11px;
    }
    .cp-box li span{
        margin-right: 20px;
        font-family: 'SourceHanSansCN-Normal';
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        text-align: justify;
    }
    .cp-box li h2{
        display: inline-block;
        font-family: 'DINAlternate-Bold';
        font-size: 30px;
        color: #333333;
        letter-spacing: 0;
        text-align: justify;
        font-weight: 700;
        vertical-align: -5px;
    }
    .cp-box li .call{
        background: url("./img/call.png") no-repeat;
    }
    .cp-box li .time{
        background: url("./img/time.png") no-repeat;
    }
    .cp-box li .address{
        background: url("./img/address.png") no-repeat;
    }
    .cp-box li .www{
        background: url("./img/www.png") no-repeat;
    }
    .cp-box .copyright{
        position: relative;
        margin-top: 30px !important;
        padding-bottom: 0 !important;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        color: #666;
        z-index: 5;
    }
    .cp-box .copyright span{
        margin-right: 10px;
    }
    .cp-box .copyright a{
        color: #666;
        text-decoration: none;
    }
    .cp-box .copyright a:hover{
        color: #666;
        text-decoration: underline;
    }
    .cp-box .copyright .police{
        display: inline-block;
        width: 12px;
        height: 12px;
        background: url("./img/emblem.png") no-repeat;
        vertical-align: 0;
        margin-right: 5px;
    }
    .footer-bg{
        width: 699px;
        height: 466px;
        position: absolute;
        top: -146px;
        left: 620px;
        background: url("./img/footer-bg.png") no-repeat;
        background-size:100% 100%;
        text-indent: -9999%;
    }
`;

export default IndexStyleComponent;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    .top-box{
        padding-top:48px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-direction: column;

        & h2{
            color: #3366FF;
            font-size: 30px;
            font-weight: 700;
        }

        & img{
            width:393px;
            height:28px;
            margin-top:4px;
        }
    }

    .content-box{
        position: relative;
        background:url(./img/ywtp.png) no-repeat center center;
        width:100%;
        height:566px;
        margin-top:24px;
        margin-bottom:188px;
        margin-left:20px;

        & .circle{
            width:80px;
            height:80px;
            border-radius: 50%;
            background: #6EC5FF;
            border: 6px solid #EEE;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            
            font-size: 14px;
            line-height: 20px;
            color:#fff;

            user-select: none;
            cursor: pointer;
            transition: .2s;
        }

        .circle-content{
            position: absolute;
            text-align: center;

            & span{
                width:100%;
                display:block;
                font-size: 12px;
                line-height: 17px;
                color:#666;
            }
        }

        & .big{
            width:160px;
            height:160px;
            border: 8px solid #EEE;
            flex-direction: column;

            & span{
                display:inline-block;
                font-weight: 700;
                margin-top:9px;
                text-align: center;
            }
        }

        .spaqjgypt{

            background: #1890FF;
            top:134px;
            left:217px;

            & img{
                width:44px;
                height:51px;
            }

            & span{
                width:84px;
            }
        }

        .scjgypt{
            background: #FF3F35;
            top:189px;
            left:476px;

            & img{
                width:56px;
                height:56px;
            }

            & span{
                width:56px;
            }
        }

        .tzsbjgypt{
            background: #3366FF;
            top:228px;
            left:752px;

            & img{
                width:50px;
                height:50px;
            }

            & span{
                width:84px;
            }
        }
    }

    .kcgl{
        top:33px;
        left:645px;
    }
    .kcgl-content{
        width:48px;
        top:128px;
        left:645px;
    }

    .xsgl{
        top:0;
        left:360px;
    }
    .xsgl-content{
        width:72px;
        top:95px;
        left:360px;
    }


    .cpgl{
        top:59px;
        left:75px;
    }
    .cpgl-content{
        width:60px;
        top:155px;
        left:75px;
    }

    .scgcgl{
        top:215px;
        left:0;
    }
    .scgcgl-content{
        width:60px;
        top:311px;
        left:0;
    }

    .cggl{
        top:338px;
        left:117px;
    }
    .cggl-content{
        width:72px;
        top:434px;
        left:117px;
    }

    .ygys{
        top:456px;
        left:-39px;
    }
    .ygys-content{
        width:59px;
        top:552px;
        left:-39px;
    }

    .qyzz{
        top:313px;
        left:364px;
    }
    .qyzz-content{
        width:72px;
        top:409px;
        left:364px;
    }

    .gyszz{
        top:481px;
        left:306px;
    }
    .gyszz-content{
        width:84px;
        top:calc(100% + 11px);
        left:306px;
    }

    .cyry{
        top:486px;
        left:527px;
    }
    .cyry-content{
        width:72px;
        top:calc(100% + 16px);
        left:527px;
    }

    .sbgl{
        top:446px;
        left:739px;
    }
    .sbgl-content{
        width:72px;
        top:calc(100% - 25px);
        left:739px;
    }

    .sggz{
        top:446px;
        left:937px;
    }
    .sggz-content{
        width:72px;
        top:calc(100% - 25px);
        left:937px;
    }

    .rygl{
        top:358px;
        left:1091px;
    }
    .rygl-content{
        width:72px;
        top:454px;
        left:1091px;
    }

    .cxtj{
        top:159px;
        left:1011px;
    }
    .cxtj-content{
        width:96px;
        top:256px;
        left:1011px;
    }

    & .move{
        animation: ywtpMove 1.2s linear infinite;
        animation-fill-mode: forwards;
    }

    @keyframes ywtpMove {
        0%{
            margin-top:0;
        }
        25%{
            margin-top:-4px;
        }
        50%{
            margin-top:0;
        }
        75%{
            margin-top:4px;
        }
        100%{
            margin-top:0;
        }
    }
`;

export default IndexStyleComponent;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    height:100%;

    .advantage-box{
        width: 100%;
        height: 100%;
        background: url("./img/group-6.png") no-repeat center center rgb(237,241,245);
        background-size:100% 100%；
    }
    .advantage-content{
        width: 100%;
        margin-top: 84px;
        display: inline-block;
    }
    .content-left{
        width: 532px;
        display: inline-block;
    }
    .content-left ul{
        box-shadow: 6px 8px 4px 0 rgba(13,52,144,.2);
        position: relative;
        z-index: 4;
    }
    .content-left li{
        font-size: 28px;
        color: #0D1A26;
        line-height: 36px;
        background: rgb(191,224,255);
        padding: 42px;
        word-break: inherit;
        user-select: none;
        cursor: pointer;
    }
    .content-active{
        position: relative;
        background: #1890FF !important;
        box-shadow: 6px 8px 4px 0 rgba(13,52,144,0.20) !important;
        color: #fff !important;
        z-index: 5;
    }

    .content-right{
        width: 668px;
        display: inline-block;
    }
    .content-right ul{
        display: inline-block;
        box-shadow: 0 12px 20px 0 #D8E0E6;
        background: #fff;
        height: 600px;
    }
    .content-right li{
        background: #FFFFFF;
    }
    .content-right li img{
        width:100px;
        height:100px;
    }
    .content-right .first{
        margin-left: 56px !important;
        margin-top: 32px !important;
    }
    .content-right .other-l{
        margin-top: 32px !important;
        margin-left: 56px !important;
    }
    .content-right .other-r{
        margin-top: 32px !important;
        margin-right: 56px !important;
    }
    .content-right h2{
        display: inline-block;
        width: 60%;
        font-size: 20px;
        color: #0D1A26;
        line-height: 28px;
        font-weight: 700;
        margin-top: 30px !important;
        margin-left: 56px !important;
    }
    .content-right p{
        display: inline-block;
        width: 390px;
        font-size: 14px;
        color: #314659;
        line-height: 22px;
        margin-left: 56px !important;
        margin-top: 8px !important;
        padding-bottom: 24px;
        border-bottom: 1px solid #EBEDF0;
    }
    .content-right .last-p{
        border-bottom: none;
    }

    .text-block{
        position: relative;
        height: 600px;
        width: 668px;
        background: #fff;
        box-shadow: 0 12px 20px 0 #D8E0E6;
    }
    .text-block div{
        position: absolute;
        top: 170px;
        left: 50px;
    }
    .text-block p{
        width: 448px;
        font-size: 14px;
        color: #333333;
        text-align: justify;
        line-height: 22px;
        border: none;
        margin-top: 16px !important;
        margin-left: 66px !important;
    }
    .text-block p span{
        font-size: 14px;
        color: #1890FF;
        text-align: justify;
        line-height: 22px;
    }
    .text-block .r{
        position: absolute;
        transform: scaleX(-1) scaleY(-1);
        right: -66px;
    }
    @media screen and (max-height: 901px) {
        .advantage-content{
            margin-top:10px !important;
        }
    }
    @media screen and (min-height: 1280px) {
        .advantage-box>.h{
            height:100%;
            display:flex;
            align-items: center;
        }
    }
`;

export default IndexStyleComponent;
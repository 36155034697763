import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    height:100%;

    .history-box{
        width: 1249px;
        height: 585px;
        background: url("./img/bg_fzlc.png") no-repeat center center;
        position: relative;
        margin: 91px auto 0 auto;
    }
    .history-box p{
        position: absolute;
    }
    .history-logo{
        position: absolute;
        background: url("./img/title_fzlc.png") no-repeat;
        width: 190px;
        height: 60px;
        background-size:100% 100%;
        left: 32px;
        top: -41px;
        text-indent: -9999%;
    }
    .pw{
        font-family: 'SourceHanSansCN-Normal';
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 21px;
    }
    .pw-1{
        width: 158px;
    }
    .pw-2{
        width: 195px;
    }
    .pw-3{
        width: 168px;
    }
    .year{
        position: absolute;
        font-family: 'SourceHanSansCN-Normal';
        font-size: 14px;
        color: #333333;
        letter-spacing: 0;
        text-align: center;
        line-height: 21px;
    }
    .year-offset-1{
        left: 130px;
        top: 399px;
    }
    .text-offset-1{
        left: 74px;
        top: 289px;
    }
    .year-offset-2{
        left: 307px;
        top: 344px;
    }
    .text-offset-2{
        left: 251px;
        top: 416px;
    }
    .year-offset-3{
        left: 478px;
        top: 274px;
    }
    .text-offset-3{
        left: 421px;
        top: 164px;
    }
    .year-offset-4{
        left: 625px;
        top: 224px;
    }
    .text-offset-4{
        left: 574px;
        top: 296px;
    }
    .year-offset-5{
        left: 778px;
        top: 157px;
    }
    .text-offset-5{
        left: 703px;
        top: 5px;
    }
    .year-offset-6{
        left: 945px;
        top: 103px;
    }
    .text-offset-6{
        left: 889px;
        top: 175px;
    }

    @media screen and (max-height: 901px) {
        .history-box{
            margin-top:40px;
        }
    }
    @media screen and (min-height: 1280px) {
        .fiexh{
            display:fiex;
            height:100%;
            align-items: center;
        }
    }
`;

export default IndexStyleComponent;
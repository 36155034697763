import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import Footer from '../../components/footer/index';
import IndexStyleComponent from './indexStyleComponent';

import Swiper from 'swiper/dist/js/swiper.js';
import 'swiper/dist/css/swiper.min.css';

import RcViewer from '@hanyk/rc-viewer';

class Honour extends Component{

    componentDidMount(){
        new Swiper ('.swiper-container', {
            loop: true,  //循环
            speed:1000,
            autoplay : {
                delay:4000
            },
            pagination: {  //分页器
                el: '.swiper-pagination'
            }
        });
    }
    componentWillUpdate(){
        // console.log(23);
    }

    render(){
        const options={};
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="h fiexheight-2">
                        <div className="honour-logo">荣誉资质</div>
                        <div className="honour-swiper">
                            <div className="swiper-container">
                                <div className="swiper-wrapper">
                                    <div className="swiper-slide">
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-01.png" alt="z-01" />
                                            </RcViewer>
                                        </div>
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-02.png" alt="z-02" />
                                            </RcViewer>
                                        </div>
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-03.png" alt="z-03" />
                                            </RcViewer>
                                        </div>
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-04.png" alt="z-04" />
                                            </RcViewer>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-05.png" alt="z-05" />
                                            </RcViewer>
                                        </div>
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-06.png" alt="z-06" />
                                            </RcViewer>
                                        </div>
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-07.png" alt="z-07" />
                                            </RcViewer>
                                        </div>
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-08.png" alt="z-08" />
                                            </RcViewer>
                                        </div>
                                    </div>
                                    <div className="swiper-slide">
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-09.png" alt="z-09" />
                                            </RcViewer>
                                        </div>
                                        <div>
                                            <RcViewer options={options}>
                                                <img src="./img/z-10.png" alt="z-10" />
                                            </RcViewer>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer></Footer>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Honour;
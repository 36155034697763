import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

import $ from 'jquery';
import $data from './content';

class Advantage extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'#666'
          }
        }
      }

    componentDidMount(){
        
        let contentArr=$data;
        let changeParentElm=$(".content-right");
        let hoverEleIndex=null;

        // 根据移入元素渲染对应右侧内容
        $(".content-left li").mouseenter(function(){
            $(".content-left li").removeClass("content-active");
            $(this).addClass("content-active");
            hoverEleIndex=$(this).index();
            changeParentElm.empty().append(contentArr[hoverEleIndex]);
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="advantage-box">
                        <div className="h">
                            <div className="advantage-content">
                                <div className="content-left">
                                    <ul>
                                        <li className="content-active">由笔端向指尖，企业管理便利化</li>
                                        <li>由粗放向细分，企业管理精细化</li>
                                        <li>由随性向约束，企业管理刚性化</li>
                                        <li>由“规”向“扶”，监管企业柔性化</li>
                                        <li>由面扫到点射，监管资源配置高效化</li>
                                    </ul>
                                </div>
                                <div className="content-right flr">
                                    <ul>
                                        <li>
                                            <img src="./img/smallprogram.png" alt="smallprogram" className="fll first"/>
                                            <h2>注册便利</h2>
                                            <p>在“微信小程序”上填写手机号就能注册，通过会议形式集中注册上线，一次搞定</p>
                                        </li>
                                        <li>
                                            <img src="./img/wechat.png" alt="wechat" className="flr other-r"/>
                                            <h2>使用便利</h2>
                                            <p>只要会用“微信”就可以使用，不需要单独购买电脑和学习打字，企业初始成本几乎为零</p>
                                        </li>
                                        <li>
                                            <img src="./img/photo.png" alt="photo" className="fll other-l"/>
                                            <h2>录入便利</h2>
                                            <p>通过拍照上传、商品条码扫描、智能选择等功能，一个人，一部手机在厂里转一圈，就将原辅料采购、生产、检验到销售等生产记录及时、及地录入</p>
                                        </li>
                                        <li>
                                            <img src="./img/bbconvience.png" alt="bbconvience" className="flr other-r"/>
                                            <h2>报备便利</h2>
                                            <p className="last-p">生产报备、企业停产报告、主体责任自查报告等均可在线提交，真正做到“一次也不用跑”</p>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <FooterCopy pStyle={this.state.style}></FooterCopy>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Advantage;
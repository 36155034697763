import styled from 'styled-components';

const IndexStyleComponent = styled.div`
    background: #1F3D99;
    width:100%;

    .content-box{
        width:100%;
        padding-top:40px;
        padding-bottom:20px;
        display: flex;
        justify-content: center;
        align-content: center;

        & .item{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width:190px;
            margin:0 44px;

            & img{
                width:190px;
                height:190px;
                display:block;
            }

            & .tit{
                color: #ADC8FF;
                font-size:14px;
                width:100%;
                text-align: center;
                padding-top:12px;
                display:block;
            }
        }
    }

    .footer{
        background: #1A3380;

        .h{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size:14px;
            color: #3D60CC;
            line-height: 20px;
            flex-direction: column;

            & .item{
                display: flex;
                justify-content: center;
                align-items: center;
                padding:20px 10px;

                & p a{
                    color: #3D60CC !important;
                }

                & .tel{
                    width:190px;
                    marginm:0 44px;
                }
            }

            & .item.bdb{
                border-bottom: 1px solid #3655B3;
                width:100%;
            }

            & .mr{
                margin-right: 20px !important;
            }

            & .item.expd1{
                padding:10px 0 0 0;
            }

            & .item.expd2{
                margin-top:5px;
                padding:0 0 30px 0;
            }
        }
    }
`;

export default IndexStyleComponent;
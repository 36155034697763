import styled from 'styled-components';

const IndexStyleComponent = styled.div`
    background: linear-gradient(60deg, #4480EB 0%, #04BDFD 98%);
    height: 100%;
    width: 100%;

    &>.h{
        height:100%;
    }
    .law-content{
        height: 100%;
        width: 100%;
    }
    .law-box{
        width: 100%;
        height: calc(100% - 82px);
        background: url("./img/law-bg.png") no-repeat bottom left;
        overflow: hidden;
    }
    .law-list{
        margin-top: 134px;
        width: 100%;
    }
    @media screen and (min-height: 1280px) {
        .law-list {
            margin-top: 0;
        }
        .law-box{
            display:flex;
            align-items: center;
        }
    }
    .law-list li{
        float: left;
        background: #fff;
        margin-right: 12px !important;
        margin-bottom: 12px !important;
    }
    .law-list a{
        display: block;
        padding: 40px 24px;
    }
    .law-list img{
        width: 72px;
        height:60px;
        display: inline-block;
    }
    .law-list h3{
        width: 260px;
        height: 44px;
        font-size: 16px;
        color: #0D1A26;
        display: inline-block;
        padding-left: 16px;
        vertical-align: middle;
        font-weight: 700;
    }
`;

export default IndexStyleComponent;
import React,{Component,Fragment} from 'react';
// import './index.css';

import Head from '../../components/head';
import IndexStyleComponent from './indexStyleComponent';
import FooterCopy from '../../components/footer-copy';

import $ from 'jquery';

import globalData from '../../configs/configs';

class Law extends Component{

    constructor(props) {
        super(props)
        this.state = {
          style:{
              color:'rgba(255,255,255,0.87)'
          }
        }
      }

    componentDidMount(){
        
        $.ajax({

            type:"GET",
            url:globalData.lawRequestUrl,

            success(data){
                let res=data.data;

                for(let i=0;i<6;i++){
                    $("<li>\n" +
                    "    <a href=\"" + res[i].fullUrl + "\" title=\"" + res[i].name + "\" target=\"_blank\">\n" +
                    "        <img src=\"./img/chatu_law.png\" alt=\"chatu_law\"/>\n" +
                    "        <h3>《" + res[i].name + "》</h3>\n" +
                    "    </a>\n" +
                    "</li>").appendTo($(".law-list ul"));
                }
            },
            error(xhr){
                console.log(xhr.status);
            }
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <Head></Head>
                    <div className="h">
                        <div className="law-content">
                            <div className="law-box">
                                <div className="law-list">
                                    <ul className="clearfix"></ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <FooterCopy pStyle={this.state.style}></FooterCopy>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Law;
import styled from 'styled-components';

const IndexStyleComponent = styled.div `
    position: relative;
    z-index: 100;
    background:#fff;

    @font-face{
        font-family: SourceHanSansCN-Bold;
        src:url();

        font-family: SourceHanSansCN-Heavy;
        src:url();

        font-family: SourceHanSansCN-Medium;
        src:url();
    }

    .top-nav{
        height: 82px;
        line-height: 82px;
        background: #fff;
        position: relative;
    }
    .logo-box h1,.logo-box i{
        display: inline-block;
    }
    .logo-box h1{
        font-size: 32px;
        color: #0D1A26;
        margin-top:30px;
        position: absolute;
        left:80px;
        top:0;

        &>img{
            width:432px;
            display: inline-block;
            vertical-align: -5px;
        }
    }
    .logo-box i{
        width: 50px;
        height:90%;
        background: url("./img/logo.png");
        background-size:100% 100%;
        text-indent: -9999%;
        vertical-align: -10px;
        margin-right: 12px;
    }
    .logo-box a{
        display: block;
        height: 72px;
    }
    .nav-box li{
        float: left;
        margin-left: 28px !important;
        position: relative;
    }
    .nav-box li:hover div{
        display: block;
    }
    .nav-box li a{
        font-size: 16px;
        display: block;
        font-weight: 700;
        color: #333;
        position: relative;
    }
    .link-active{
        color: #1890FF !important;
    }

    .nav-box .child-menu{
        position: absolute;
        left: calc(50% - 45px);
        top: 82px;
        width: 90px;
        display: none;
    }
    .nav-box .child-menu li{
        margin-left: 0 !important;
        background: #FFFFFF;
        border: 1px solid rgba(0,0,0,0.09);
        border-radius: 2px;
        width: 100%;
        padding: 9px 13px;
    }
    .nav-box .child-menu a{
        display:
        display:block;
        font-size: 15px;
        color: #333333;
        text-align: center;
        font-weight: 400;
        line-height: 1;
        height: 20px;
        line-height: 20px;
    }
    .nav-box .child-menu li:hover{
        background: #D1E9FF;
        border: 1px solid rgba(0,0,0,0.09);
        border-radius: 2px;
        border-radius: 2px;
    }
    .nav-box .child-menu .childmenu-select{
        background: #1890FF;
        border: 1px solid rgba(0,0,0,0.09);
        border-radius: 2px;
        border-radius: 2px;
    }
    .childmenu-active{
        color: #FFFFFF !important;
    }
    .next-btn{
        position: absolute;
        width: 40px;
        height: 40px;
        left: calc(50% - 20px);
        z-index: 10;
        animation: move .8s infinite;
        animation-timing-function: ease-out;
    }
    @keyframes move
    {
    0% {top:820px;}
    50% {top:830px;}
    100% {top:820px;}
    }
    .next-btn a{
        display: block;
        width: 40px;
        height: 40px;
    }
    .next-btn .def{
        background: url("./img/next-def.png") no-repeat center center;
    }
    .next-btn .oth{
        background: url("./img/next-oth.png") no-repeat center center;
    }
`;

export default IndexStyleComponent;
import React,{Component,Fragment} from 'react';
import { NavLink,Link } from 'react-router-dom';
// import './index.css';
import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';
import globalData from '../../configs/configs';

import linkList from './link-list';

class Head extends Component{

    componentDidMount(){

    };

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <div className="top-nav">
                            <div className="logo-box fll">
                                <NavLink to="/preview">
                                    <h1><i>logo</i></h1>
                                </NavLink>
                            </div>
                            {/* {this.state.comp.res && this.state.style.bottom && <div className="next-btn" style={this.state.style}>
                                {this.state.comp.imgUrl ? <Link to={this.state.comp.linkTo} className="def"></Link> : <Link to={this.state.comp.linkTo} className="oth"></Link>}
                            </div>} */}
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Head;
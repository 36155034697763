import styled from 'styled-components';

const IndexStyleComponent = styled.div`
    .copyright{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        position: fixed;
        font-family: MicrosoftYaHei;
        font-size: 12px;
        z-index: 5;
        text-align: center;
        left: 0;
        bottom: 30px;

        & span{
            margin-right:10px;
        }

        & .police{
            display: inline-block;
            width: 12px;
            height: 12px;
            vertical-align: 0px;
            margin-right: 5px;
            background: url(./img/emblem.png) no-repeat;
        }
    }
`;

export default IndexStyleComponent;
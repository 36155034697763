import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';

import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';

class Ywtp extends Component{

    componentDidMount(){
        $(function(){
            $(".ywtp .circle").hover(function(){
                $(this).addClass('move');
            },function(){
                $(this).removeClass('move');
            });
        });
    }

    render(){
        return (
            <Fragment>
                <IndexStyleComponent>
                    <div className="h">
                        <div className="top-box">
                            <h2>业务图谱</h2>
                            <img src="./img/Frame37.png" alt="title" />
                        </div>
                        <div className="content-box ywtp">
                            <div className="circle kcgl">库存管理</div>
                            <div className="circle-content kcgl-content">
                                <span>库存查询</span>
                                <span>原辅料</span>
                                <span>添加剂</span>
                                <span>半成品</span>
                                <span>成品</span>
                            </div>
                            <div className="circle xsgl">销售管理</div>
                            <div className="circle-content xsgl-content">
                                <span>销售对象</span>
                                <span>销售商品明细</span>
                                <span>销售日期</span>
                            </div>
                            <div className="circle cpgl">产品管理</div>
                            <div className="circle-content cpgl-content">
                                <span>成品条形码</span>
                                <span>保质期</span>
                                <span>规格型号</span>
                            </div>
                            <div className="circle scgcgl">生产过程管理</div>
                            <div className="circle-content scgcgl-content">
                                <span>半成品生产</span>
                                <span>成品生产</span>
                                <span>生产报备</span>
                            </div>
                            <div className="circle cggl">采购管理</div>
                            <div className="circle-content cggl-content">
                                <span>采购商品明细</span>
                                <span>采购票据</span>
                                <span>采购日期</span>
                                <span>供应商</span>
                            </div>
                            <div className="circle ygys">阳光云视</div>
                            <div className="circle-content ygys-content">
                                <span>视频直播</span>
                                <span>AI监控预警</span>
                            </div>
                            <div className="circle qyzz">企业资质</div>
                            <div className="circle-content qyzz-content">
                                <span>企业风险等级</span>
                                <span>证照信息</span>
                            </div>
                            <div className="circle gyszz">供应商资质</div>
                            <div className="circle-content gyszz-content">
                                <span>供应商证照信息</span>
                                <span>法人信息</span>
                                <span>联系地址</span>
                            </div>
                            <div className="circle cyry">从业人员</div>
                            <div className="circle-content cyry-content">
                                <span>从业人员管理</span>
                                <span>从业人员考核</span>
                                <span>在线练习</span>
                                <span>培训记录</span>
                            </div>
                            <div className="circle sbgl">设备管理</div>
                            <div className="circle-content sbgl-content">
                                <span>设备登记</span>
                                <span>设备基本信息</span>
                                <span>设备检验信息</span>
                                <span>设备使用信息</span>
                                <span>设备信息变更</span>
                            </div>
                            <div className="circle sggz">施工告知</div>
                            <div className="circle-content sggz-content">
                                <span>施工告知记录</span>
                                <span>使用单位</span>
                                <span>施工单位</span>
                                <span>上报材料</span>
                                <span>受理信息</span>
                            </div>
                            <div className="circle rygl">人员管理</div>
                            <div className="circle-content rygl-content">
                                <span>人员录入</span>
                                <span>人员基本信息</span>
                                <span>持证信息</span>
                                <span>操作设备</span>
                            </div>
                            <div className="circle cxtj">查询统计</div>
                            <div className="circle-content cxtj-content">
                                <span>设备综合查询</span>
                                <span>临检设备查询</span>
                                <span>检验超期查询</span>
                                <span>证书超期查询</span>
                                <span>监督检查记录查询</span>
                            </div>
                            <div className="circle big spaqjgypt">
                                <img src="./img/say.png" alt="circleLogo" />
                                <span>食品安全监管云平台</span>
                            </div>
                            <div className="circle big scjgypt">
                                <img src="./img/scjgypt.png" alt="circleLogo" />
                                <span>市场监管云平台</span>
                            </div>
                            <div className="circle big tzsbjgypt">
                                <img src="./img/tzsbjgypt.png" alt="circleLogo" />
                                <span>特种设备智慧云平台</span>
                            </div>
                        </div>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Ywtp;
import React,{Component,Fragment} from 'react';
import { Route, Switch, Redirect,NavLink } from 'react-router-dom';
// import './index.css';
import IndexStyleComponent from './indexStyleComponent';

import $ from 'jquery';

class Footer extends Component{

    constructor(props) {
        super();
        this.state = {pStyle: props.pStyle};
    }

    render(){
        const from = window._mode.from;
        return (
            <Fragment>
                <IndexStyleComponent className="fiexheight">
                    <div className="h">
                        <p className="copyright" style={this.state.pStyle}>
                            {from !== 'gx' ? <span style={this.state.pStyle}>版权所有&nbsp;©</span> : null}
                            {from !== 'gx' ? <span style={this.state.pStyle}>2019杭州南开承盛物联科技有限公司</span> : null}
                            <a href="http://beian.miit.gov.cn/publish/query/indexFirst.action" className="cy-link"
                            target="_blank">
                                <span style={this.state.pStyle}>浙ICP备16028625号-2</span>
                            </a>
                            <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010902002093"
                            className="cy-link" target="_blank" style={this.state.pStyle}>
                                <i className="police"></i>
                                <span style={this.state.pStyle}>浙公网安备 33010902002093号</span>
                            </a>
                        </p>
                    </div>
                </IndexStyleComponent>
            </Fragment>
        );
    };
};

export default Footer;
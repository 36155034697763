import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    position: relative;
    height:100%;

    .honour-logo{
        background:url("./img/title_honor.png") no-repeat;
        width: 190px;
        height: 60px;
        text-indent: -9999%;
        margin-top: 50px;
    }
    .honour-swiper .swiper-wrapper{
        width: 1200px;
        height: 218px;
        margin-top: 20px;
        margin-bottom: 150px;
    }
    .honour-swiper .swiper-slide div{
        width: 290px;
        height: 218px;
        background: #FAFAFA;
        margin-left: 10px;
        display: inline-block;
        overflow: hidden;
        position: relative;
        text-align: center;
    }
    .honour-swiper .swiper-slide img{
        height: 100%;
        display: inline-block;
        cursor: pointer;
    }
    @media screen and (max-height: 901px) {
        .fiexheight .cp-box{
            margin-top: 20px;
        }
        .honour-logo{
            margin-top:10px;
        }
    }
    @media screen and (min-height: 1280px) {
        .honour-logo{
            margin-top:-331px;
        }
        .fiexheight-2{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: center;
        }
    }
`;

export default IndexStyleComponent;
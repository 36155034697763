import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    height:calc(100% - 251px);

    &>.h{
        height:calc(100% - 82px);
    }

    .error-box{
        user-select: none;
        width: 100%;
        height: 100%;
        background: url("./img/404.png") no-repeat center center;
        background-size: 179px 179px;
        text-align: center;
        position: relative;
        
        h2{
            position: absolute;
            font-size: 14px;
            color: rgb(24,144,255);
            letter-spacing: 1px;
            top:73%
            width: 100%;
        }
    }
    @media screen and (max-height: 901px) {
        .fiexheight .cp-box{
            margin-top: 20px;
        }
        .honour-logo{
            margin-top:10px;
        }
    }
    @media screen and (min-height: 1280px) {
        .honour-logo{
            margin-top:-331px;
        }
        .fiexheight-2{
            height:100%;
            display:flex;
            flex-direction: column;
            justify-content: center;
        }
        .fixdheight{
            display:flex;
            align-items: center;
        }
    }
`;

export default IndexStyleComponent;
import styled from 'styled-components';

const IndexStyleComponent = styled.div`

    background: url(./img/bg_index.png) no-repeat;
    background-size:100% 100%;
    height: 800px;
    width: 100%;

    .queue-simple{
        display:inline-block;
        width:50%;
        vertical-align: top;
    }

    .l,.r{
        margin-top:144px;
        display:inline-block;
        vertical-align: top;
        width：50%;
    }
    .logo{
        width:148px;
        height:148px;      
    }

    .title1{
        margin-top:48px;
        width:440px;
        height:47px;
    }
    .title2{
        margin-top:18px;
        width:485px;
        height:40px;
    }
    .l-content{
        margin-top:24px;
        &>.content{
            width:406px;
            color: rgba(255, 255, 255, 0.87);
            font-size:14px;
            line-height:24px;
        }
    }
    .btn-box{

        &.ex-margin{
            margin-top:16px !important;
        }

        & a{
            display:inline-block;
        }

        & .button{
            color: #fff !important;
            padding:10px 20px;
            border-radius: 32px; 
            outline:none;
            border:none;
            overflow:hidden;
            cursor: pointer;
            font-size: 18px;
            border: 1px solid rgba(255, 255, 255, 0.54);
        }

        & .button.b-ml{
            margin-left:16px;
        }
    }
    .title3{
        width:100%;
        height:48px;
        display:inline-block;
        text-align: right;
        & img{
            width:437px;
        }
    }
    .title4{
        width:100%;
        height:40px;
        margin-top:18px;
        display:inline-block;
        text-align: right;
        & img{
            width:537px;
        }
    }
    .title-r{
        display:inline-block;
        width:100%;
    }
    .r-content{
        margin-top:25px;
        width:100%;
        display: flex;
        justify-content: flex-end;

        & p{
            width:426px;
            font-size: 14px;
            line-height: 24px;
            color: rgba(255, 255, 255, 0.87);
        }
    }
    .r-box{
        margin-top:40px;

        & .logo{
            display:inline-block;
            vertical-align: top;
            float:right;
        }
        & .btn-box{
            display:inline-block;
            vertical-align: top;
            margin-right:24px;
            float:right;
        }
        & .btn-box a{
            display:inline-block;
        }
    }
`;

export default IndexStyleComponent;